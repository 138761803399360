import React, { useState, useEffect } from 'react';

// importing components

import { Link, Outlet } from "react-router-dom";

// importing jQ

// importing icons for designing

// importing content\

// Add slideDown animation to Bootstrap dropdown when expanding.

import logo from "../../media/websync.png"
// import logo2 from "../../media/logobR.png"

import "../../vendor/fontawesome/css/all.css"

// importing navitems for navbar
import { WebDevelopment, DigitalMarketing, AppDevelopment } from './ServiceMenuItems.js'

// explore more pop-up
import ExploreMorePopUp from './ExploreMorePopUp.jsx';

const Navbar = () => {

  const [isReduced, setReduced] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        // Change the threshold (50) to a different value as needed
        setReduced(true);
      } else {
        setReduced(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  return (
    <>
      <div className='navbar-main-1'>
        <div className='navbar-container'>
          <div className='container-fluid'>
            <div className="navbar-2-content">
              <div className='row'>
                <div className="col d-none d-md-flex align-items-start align-items-center">
                  <span className='d-none d-md-inline'>+91 97666 96550
                    <span className='d-none d-md-inline'> | contact@websyncsolution.com </span>
                    | Mon-Sat : 10AM - 8PM</span>
                  <span className='d-inline d-md-none'>contact@websyncsolution.com</span>
                </div>
                <div className="col-md-5 d-md-flex justify-content-end align-items-center">
                  <span className='d-md-inline '>
                    Follow Us On # websyncsolution
                  </span>
                  <div className="nav-social text-light d-inline">
                    <i className="p-2 fa-brands fa-facebook"></i>
                    <i className="p-2 fa-brands fa-instagram"></i>
                    {/* <i className="p-2 fa-brands fa-twitter"></i> */}
                    <i className="p-2 fa-brands fa-linkedin"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* main navbar */}
      <div className={`navbar-main-2 container-fluid shadow position-sticky top-0 ${isReduced ? 'navbar-reduced-height' : ''}`}>
        <div className="">
          <nav className={`navbar navbar-expand-md navbar-dark ${isReduced ? 'navbar-reduced-height' : ''}`}>

            <Link className="navbar-brand" to="#">
              <img className={`img-fluid org-logo-width ${isReduced ? 'scrolled-logo-width' : ''}`} src={logo} alt="websync logo" />
            </Link>

            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#collapseMenu" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className=" offcanvas offcanvas-end bg-dark justify-content-md-center align-items-md-center fw-thin
            dropdown-menu-color-items" data-bs-backdrop="static" tabIndex="1" id="collapseMenu">

              <div className="offcanvas-header">
                <button type="button" className="btn btn-outline-light" data-bs-dismiss="offcanvas" aria-label="Close"><i className=" fa-solid fa-xmark"></i></button>
                {/* <span className="text-light py-2">Welcome, Get the Best B2B Solution Here</span> */}
              </div>
              <div className="offcanvas-body text-center menu-items-custom">
                <ul className="navbar-nav">
                  <li className="nav-items"><Link className="nav-link fw-semibold" to="/">Home</Link></li>
                  <li className="nav-items"><Link className="nav-link fw-semibold" to="/about">About</Link></li>
                  <li className="nav-items"><Link className="nav-link fw-semibold" to="/testimonial">Testimonial</Link></li>

                  <li className="nav-item dropdown position-static">
                    <Link className="nav-link dropdown-toggle menu-items-custom fw-semibold" href="#" role="button" data-bs-toggle="dropdown">
                      Our Services
                    </Link>
                    <ul id='dropmegamenu' className="dropdown-menu">
                      <div className='container-fluid'>
                        <div className='row d-flex flex-column flex-md-row justify-content-evenly'>
                          <div className='col'>
                            <h2 className='fs-4 fw-bolder'>Web <span className='text-primary'>Development</span></h2>
                            <ul>
                              {
                                WebDevelopment.map((element) => {
                                  return (
                                    <li key={element.webDevServiceId} className='cus-items'>
                                      <Link className='nav-link' to={`/${element.serviceCat}/${element.ServiceName}`}>
                                        <i className='fa-solid fa-angles-right text-danger'></i> {element.ServiceName}
                                      </Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                          <div className='col'>
                            <h2 className='fs-4 fw-bolder'>Digital <span className='text-primary'>Marketing</span></h2>
                            <ul>
                              {
                                DigitalMarketing.map((element) => {
                                  return (
                                    <li li key={element.digiMarServiceId} className='cus-items' >
                                      <Link className='nav-link' to={`/${element.serviceCat}/${element.ServiceName}`} > <i className='fa-solid fa-angles-right text-danger'></i> {element.ServiceName}</Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                          <div className='col'>
                            <h2 className='fs-4 fw-bolder'>Mobile <span className='text-primary'>Application</span></h2>
                            <ul>
                              {
                                AppDevelopment.map((element) => {
                                  return (
                                    <li li key={element.appDevServiceId} className='cus-items' >
                                      <Link className='nav-link' to={`/${element.serviceCat}/${element.ServiceName}`} > <i className='fa-solid fa-angles-right text-danger'></i> {element.ServiceName}</Link>
                                    </li>
                                  )
                                })
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </ul>
                  </li>
                  <li className="nav-items"><Link className="nav-link menu-items-custom fw-semibold" to="/blogs">blogs</Link></li>

                  <li className="nav-items"><Link className="nav-link menu-items-custom fw-semibold" to="/contact">Contact</Link></li>

                  <li className="nav-items d-md-none">
                    <div className="nav-social text-light">
                      <i className="p-2 fa-brands fa-facebook"></i>
                      <i className="p-2 fa-brands fa-instagram"></i>
                      <i className="p-2 fa-brands fa-twitter"></i>
                      <i className="p-2 fa-brands fa-linkedin"></i>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="search-navbar shadow d-none d-md-flex align-items-center">
              {/* <button className='navbar-cta-button'>Explore</button> */}
              <button className={`button ${isReduced ? 'reducced-button' : ''}`} data-bs-toggle="collapse" data-bs-target="#explore-pop-up">
                Solution For
                <svg className="icon" viewBox="0 0 24 24" fill="currentColor">
                  <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" clipRule="evenodd"></path>
                </svg>
              </button>
            </div>
          </nav >
        </div >
      </div >
      {/* exlpore pop-up */}
      <ExploreMorePopUp />
      <Outlet />
    </>
  )
}

export default Navbar