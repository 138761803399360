import React from 'react'

import { Link } from "react-router-dom";

// importing data and other components
import video from "../../media/video.mp4"

import img_support from "../../media/digital-marketing-menu.jpg"

const SolutionFor = () => {
    return (
        <>

            {/* services section */}
            <div className='container-fluid my-3'>
                <div className='container'>
                    <div className='p-5 d-flex justify-content-center align-items-center flex-column'>
                        <span className='fw-bolder fs-2'>Business with <span className='extra-efect'> Ease </span> </span>
                        <div className='border border-1 w-25 position-relative'>
                            <div className='border border-4 border-primary w-25 position-absolute top-50 start-50 translate-middle'></div>
                        </div>
                    </div>
                    <div className='row gap-4 gap-md-0'>
                        <div className='col-12 col-md-6'>
                            <div className='images-combo position-relative'>
                                <img className='img-fluid new-img-content shadow-lg' src={img_support} alt="" />
                                {/* <img className='img-fluid new-img-content2 shadow-lg' src={img_support2} alt="" /> */}
                                <video className='img-fluid new-img-content2 shadow-lg' autoPlay muted loop>
                                    <source src={video} />
                                </video>
                            </div>
                        </div>
                        <div className='col-12 col-md-6'>

                            <p className='fs-1 text-center text-md-end fw-bolder'>
                                How do <span className='text-primary'> we differentiate? </span>
                            </p>
                            <p className='fw-bolder text-justify text-md-end text-center'>
                                At WebSync Solution, we redefine norms in digital excellence. We innovate, crafting impactful experiences tailored uniquely to your needs. With relentless commitment to evolution, we lead the charge in industry standards. Choose us for a transformative journey towards digital success.
                            </p>

                            <div className='d-md-flex flex-md-column flex-md-row gap-4 align-items-md-end align-items-center'>

                                <ul className='about-ul-li-new d-flex flex-wrap d-md-block'>
                                    <li className='about-li-new'>Get Code Repo</li>
                                    <li className='about-li-new'>Monthly/Weekly Reports</li>
                                    <li className='about-li-new'>Monitoring Softwares</li>
                                    <li className='about-li-new'>System Configuration</li>
                                    <li className='about-li-new'>Easy Admin Panels</li>
                                    <li className='about-li-new'>Customizable Content</li>
                                    {/* <li className='about-li-new'>Regular Updates</li> */}
                                    <li className='about-li-new'>Daily availble help lines</li>
                                </ul>

                                <div className='text-md-end text-center'>
                                    <button className='btn btn-lg btn-outline-primary rounded-4'>Get Quote</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            {/* services section */}
            <div id='core-service-section' className='container-fluid service-card-container-bg p-3 shadow-lg my-2'>
                <div className='container'>
                    <h3 className='text-center my-5 text-light'>Our <span className='fw-bold text-primary'>Core</span> Services</h3>
                    <p className='text-light text-center px-5 my-5 d-none d-md-block'>At WebSync Solution, we specialize in cutting-edge web development and digital marketing solutions. Our expertise ensures your online presence stands out and drives results.</p>
                </div>
                <div className="container">
                    <div className='row'>
                        <div className="col-12 col-md-4 core-service-grid my-3 my-md-1">
                            <div className="card rounded bg-dark border-0 shadow-lg p-3">
                                <span className='fs-3 fw-bolder text-light card-title'><i className="text-primary fa-solid fa-laptop-code"></i> Web <span className='text-primary'>Development</span></span>
                                <div className='card-body p-0'>
                                    <div className='d-flex gap-1 service-badges'>
                                        <span className='badge bg-light text-dark shadow'>Website</span>
                                        <span className='badge bg-light text-dark shadow'>Web Application</span>
                                        <span className='badge bg-light text-dark shadow'>Business Solution</span>
                                    </div>
                                    <div className='my-2'>
                                        <p className='text-secondary pe-3'>Explore WebSync Solution's transparent pricing model, designed to fit your budget and project needs. With our service cards, you get clear, upfront pricing for hassle-free decision-making.</p>
                                    </div>
                                    <div className='service-pricing-peak'>
                                        <span className='text-primary'>
                                            Plans Starting form <span className='text-warning fw-bolder service-init-price'>₹ 4999/-</span>
                                        </span>
                                    </div>

                                    {/* CTA for opining the service page of a single cat */}

                                    <div className='service-cta my-3'>
                                        <Link className='text-light text-decoration-none' to="/WebDevelopment">
                                            <button className='btn btn-primary btn-sm fw-bolder'>
                                                Learn More
                                            </button>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 core-service-grid my-3 my-md-1">
                            <div className="card rounded bg-dark border-0 shadow-lg p-3">
                                <span className='fs-3 fw-bolder text-light card-title'><i className="text-primary fa-solid fa-bullhorn"></i> Digital <span className='text-primary'>Marketing</span></span>
                                <div className='card-body p-0'>
                                    <div className='d-flex gap-1 service-badges'>
                                        <span className='badge bg-light text-dark shadow'>SMM</span>
                                        <span className='badge bg-light text-dark shadow'>SEO</span>
                                        <span className='badge bg-light text-dark shadow'>Paid Ads</span>
                                        <span className='badge bg-light text-dark shadow'>GMB SEO</span>
                                    </div>
                                    <div className='my-2'>
                                        <p className='text-secondary pe-3'>WebSync Solution's digital marketing service cards offer transparent pricing, simplifying budgeting for your campaigns. Explore clear, concise options tailored to your needs and objectives.</p>
                                    </div>
                                    <div className='service-pricing-peak'>
                                        <span className='text-primary'>
                                            Plans Starting form <span className='text-warning fw-bolder service-init-price'>₹ 6499/-</span>
                                        </span>
                                    </div>

                                    {/* CTA for opining the service page of a single cat */}

                                    <div className='service-cta my-3'>
                                        <Link className='text-light text-decoration-none' to="/DigitalMarketing">
                                            <button className='btn btn-primary btn-sm fw-bolder'>
                                                Learn More
                                            </button>
                                        </Link>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-4 core-service-grid my-3 my-md-1">
                            <div className="card rounded bg-dark border-0 shadow-lg p-3">
                                <span className='fs-3 fw-bolder text-light card-title'><i className="text-primary fa-solid fa-mobile-screen-button"></i> App <span className='text-primary'>Development</span></span>
                                <div className='card-body p-0'>
                                    <div className='d-flex flex-wrap gap-1 service-badges'>
                                        <span className='badge bg-light text-dark shadow'>eCommerce App</span>
                                        <span className='badge bg-light text-dark shadow'>Data Collection App</span>
                                        <span className='badge bg-light text-dark shadow'>Promotion App</span>
                                    </div>
                                    <div className='my-2'>
                                        <p className='text-secondary pe-3'>Explore WebSync Solution's App Development Service Cards for transparent pricing and tailored solutions, simplifying your mobile project budgeting.</p>
                                    </div>
                                    <div className='service-pricing-peak'>
                                        <span className='text-primary'>
                                            Plans Starting form <span className='text-warning fw-bolder service-init-price'>₹ 4999/-</span>
                                        </span>
                                    </div>

                                    {/* CTA for opining the service page of a single cat */}

                                    <div className='service-cta my-3'>
                                        <Link className='text-light text-decoration-none' to="/AppDevelopment">
                                            <button className='btn btn-primary btn-sm fw-bolder'>
                                                Learn More
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SolutionFor