import axios from 'axios';
import React, { useState, useMemo } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Quill from 'quill';
import ImageUploader from 'quill-image-uploader';
import 'quill-image-uploader/dist/quill.imageUploader.min.css';

// Register the Quill image uploader module
Quill.register('modules/imageUploader', ImageUploader);

const BlogEditor = () => {
  const token = localStorage.getItem('token');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [thumbnail, setThumbnail] = useState(null);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    setThumbnail(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title && content && thumbnail) {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('content', content);
      formData.append('thumbnail', thumbnail);

      try {
        await axios.post('https://websyncbackend.onrender.com/admin/dashboard/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
          }
        });

        // addBlogPost(response.data.post); // Make sure this line matches how you handle the added post
        setTitle('');
        setContent('');
        setThumbnail(null);
        
        alert("Blog Posted Successfully !")

      } catch (error) {
        console.error('Error adding blog post:', error);
      }
    } else {
      alert('Please fill in all fields and select an image.');
    }
  };

  const modules = useMemo(() => ({
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      ['link'],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      ['clean']
    ]
  }), []);

  return (
    <div className="blog-editor">
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Post Title"
          value={title}
          onChange={handleTitleChange}
          required
          className="input-title"
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleThumbnailChange}
          required
          className="input-thumbnail"
        />
        <ReactQuill className='blog-input' value={content} onChange={handleContentChange} modules={modules} />
        <button type="submit" className="submit-button mt-3">Add Post</button>
      </form>
    </div>
  );
};

export default BlogEditor;
