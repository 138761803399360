import React from 'react'

// import styling
import "../Styling/components.css"

import "../vendor/fontawesome/css/all.css"

// importing section 
import Navbar from "./sections/Navbar.jsx"
import Heros from './sections/Heros'
import Sidebar from './sections/Sidebar'
import SocialMediaSidebar from './sections/SocialMediaSidebar'
import AboutSection from './sections/AboutSection.jsx'
import SolutionFor from './sections/SolutionFor'
import TechStack from "./sections/TechStack"
import Projects from './sections/Projects.jsx'
import FromUs from './sections/FromUs.jsx'
import FAQ from './sections/FAQ.jsx'
import Footer from './sections/Footer.jsx'
import ContactSection from './sections/ContactSection.jsx'
// import Post_Case from './sections/Post_Case.jsx'

   const Home = () => {
    return (
        <>
            <Navbar />
            {/* sidebar section */}
            <Sidebar />
            {/* social media sidebar */}
            <SocialMediaSidebar />
            {/* heros section */}
            <Heros />
            {/* about section for website */}
            <AboutSection />
            {/* solution for */}
            <SolutionFor />
            {/* tech stack */}
            <TechStack />
            {/* Cutomer reviews from google  */}
            <Projects />
            {/* From us contain this will contain all blogs section gallery and also other contain */}
            <FromUs />
            {/* FAQ */}
            <FAQ />
            {/* Get in touch */}
            <ContactSection />
            {/* Footer */}
            <Footer />
        </>
    )
}

export default Home