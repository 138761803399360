import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import BlogEditor from './BlogEditor';

const Dashboard = () => {
  const [adminUser, setAdminUser] = useState({});
  const [serviceSection, setServiceSection] = useState("dashboard");
  const [blogPosts, setBlogPosts] = useState([]);
  const [formName, setFormName] = useState("");
  const [isFormSelected, setIsFormSelected] = useState({
    status: false,
    message: ""
  });
  const [formData, setFormData] = useState([]);
  const navigate = useNavigate();

  // Fetch admin data on mount
  useEffect(() => {
    const fetchDataAdmin = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Unauthorized - No token provided');

        const response = await axios.get('https://websyncbackend.onrender.com/admin/dashboard', {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        });

        setAdminUser(response.data);
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        navigate("/dashmin");
      }
    };

    fetchDataAdmin();
  }, [navigate]);

  // Fetch blog posts
  useEffect(() => {
    const fetchBlogPosts = async () => {
      try {
        const response = await axios.get('https://websyncbackend.onrender.com/blog-posts');
        setBlogPosts(response.data);
      } catch (err) {
        console.error('Error fetching blog posts:', err);
      }
    };

    if (serviceSection === 'blogs') {
      fetchBlogPosts();
    }
  }, [serviceSection]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate("/dashmin");
  };

  const displayContent = (e) => {
    setServiceSection(e.target.dataset.serviceSection);
  };

  const handelFormNameChange = (e) => {
    setFormName(e.target.value);
    setIsFormSelected({
      status: true,
      message: "We Got Your Data"
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!formName) return;

      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Unauthorized - No token provided');

        const response = await axios.get(`https://websyncbackend.onrender.com/admin/dashboard/forms?form=${formName}`, {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        });

        setFormData(response.data);
      } catch (err) {
        setIsFormSelected({
          status: false,
          message: "Error While Lading the Data please try again !"
        });
        console.error("Error while fetching the form data", err);
      }
    };

    fetchData();
  }, [formName]);

  const displayDashboard = () => {
    return (
      <>
        <div className='my-3'>
          <span className='fs-2 fw-bolder text-dark text-capitalize'>Dashboard</span>
        </div>
        <div className='container=fluid'>
          <div className="container shadow-lg py-5">
            {/* Dashboard content */}
          </div>
        </div>
      </>
    );
  };

  const displayForms = () => {
    const displayTableHeader = (formData) => {
      if (formData.length === 0) return null;

      const headerKeys = Object.keys(formData[0]).filter((key) => key !== '_id' && key !== '__v');

      return (
        <>
          {headerKeys.map((key) => (
            <th key={key}>{key}</th>
          ))}
          <th>Actions</th>
        </>
      );
    };

    return (
      <>
        <div className=''>
          <span className='fs-2 fw-bolder text-dark text-capitalize'>Forms</span>
        </div>
        <div className='container=fluid'>
          <div className="container shadow-lg py-5">
            <div className=''>
              <div className=''>
                <select onChange={handelFormNameChange} className='form-select' name="formName" value={formName}>
                  <option value="" disabled>Please Select a Form To View</option>
                  <option value="contactForm">Main Forms</option>
                  <option value="sideForm">Sidebar Forms</option>
                  <option value="quoteForm">Quote Forms</option>
                </select>
              </div>
              {isFormSelected.status ? (
                <div className='p-5'>
                  <h4>{isFormSelected.message}</h4>
                  <table className='table table-bordered'>
                    <thead className='table-dark'>
                      <tr>
                        {displayTableHeader(formData)}
                      </tr>
                    </thead>
                    <tbody>
                      {formData.map((data, index) => (
                        <tr key={index}>
                          {Object.keys(data).filter((key) => key !== '_id' && key !== '__v').map((key) => (
                            <td key={key}>{data[key]}</td>
                          ))}
                          <td><button className='btn btn-danger'>Delete</button></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <h4>{isFormSelected.message}</h4>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  const displayBlogs = () => {

    // Add this function to handle the delete action
    const handleDelete = async (postId) => {
      alert("deleting blog", postId)
      try {
        const token = localStorage.getItem('token');
        if (!token) throw new Error('Unauthorized - No token provided');

        // Send a DELETE request to your backend API to delete the blog post
        await axios.delete(`https://websyncbackend.onrender.com/deleteBlog/${postId}`, {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        });

        // Remove the deleted blog post from the state
        setBlogPosts(blogPosts.filter(post => post._id !== postId));
      } catch (err) {
        console.error('Error deleting blog post:', err);
      }
    };

    return (
      <>
        <div className=''>
          <span className='fs-2 fw-bolder text-dark text-capitalize'>Blogs</span>
        </div>
        <div className='container=fluid'>
          <div className="container shadow-lg py-5">

            <BlogEditor />

            <div className='blog-list mt-5'>
              <div className='row'>
                {blogPosts.map((post) => (


                  <div key={post._id} className='col-4'>

                    <div className="blog-post card p-2">
                      <h2>{post.title}</h2>
                      <img className='card-img-top img-fluid' src={`https://websyncbackend.onrender.com${post.thumbnailUrl}`} alt={post.title} />
                      <p>{post.createdAt}</p>
                      {/* not to show blog content to admin just to user make a direct new page link when clicked on show blog */}
                      {/* <div dangerouslySetInnerHTML={{ __html: post.content }} /> */}
                      <div className='py-3 d-flex gap-3'>
                        <button className='btn btn-primary'>
                          View
                        </button>
                        {/* In your displayBlogs function, modify the delete button to call handleDelete */}
                        <button onClick={() => handleDelete(post._id)} className='btn btn-danger'>
                          Delete
                        </button>
                      </div>
                    </div>

                  </div>

                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const displayReviews = () => {
    return (
      <>
        <div className=''>
          <span className='fs-2 fw-bolder text-dark text-capitalize'>Reviews</span>
        </div>
      </>
    );
  };

  const displayCreates = () => {
    return (
      <>
        <div className=''>
          <span className='fs-2 fw-bolder text-dark text-capitalize'>Creates</span>
        </div>
      </>
    );
  };

  const loadServiceSection = () => {
    switch (serviceSection) {
      case "dashboard":
        return displayDashboard();
      case "forms":
        return displayForms();
      case "blogs":
        return displayBlogs();
      case "reviews":
        return displayReviews();
      case "creates":
        return displayCreates();
      default:
        return null;
    }
  };

  return (
    <>
      <div className="container-fluid bg-black">
        <div className='d-flex justify-content-between gap-2 py-2 px-3'>
          <span className='fs-3 text-white'>Hello, <span className='text-danger'>{adminUser.userId}</span>! Welcome to the Dashboard.</span>
          <button className='btn btn-danger' onClick={handleLogout}>Logout</button>
        </div>
      </div>
      <div className='dashboard-sidebar'>
        <div className="row w-100 h-100">
          <div className="col-3 bg-dark d-flex flex-column">
            <button onClick={displayContent} data-service-section="dashboard" className='btn btn-dark text-light fw-bold rounded-0 shadow-lg py-5'>Dashboard</button>
            <button onClick={displayContent} data-service-section="forms" className='btn btn-dark text-light fw-bold rounded-0 shadow-lg py-5'>Enquiry Forms +</button>
            <button onClick={displayContent} data-service-section="blogs" className='btn btn-dark text-light fw-bold rounded-0 shadow-lg py-5'>Blog Posts +</button>
            <button onClick={displayContent} data-service-section="reviews" className='btn btn-dark text-light fw-bold rounded-0 shadow-lg py-5'>Review Handling +</button>
            <button onClick={displayContent} data-service-section="creates" className='btn btn-dark text-light fw-bold rounded-0 shadow-lg py-5'>Create +</button>
          </div>
          <div className="col">
            <div className='container py-5'>
              {loadServiceSection()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
