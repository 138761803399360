import React, { useState } from 'react';
import axios from 'axios';

function Sidebar() {
  const [isActive, setIsActive] = useState(false);
  const [nameFocused, setNameFocused] = useState(false);
  const [nameFilled, setNameFilled] = useState(false);

  const [formSubmission, setFormSubmission] = useState({
    status: false,
    message: "",
    state: ""
  });

  const [sideBarForm, setSideBarForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: ""
  });

  const [phoneError, setPhoneError] = useState('');

  const handleNameFocus = () => {
    setNameFocused(true);
  };

  const handleNameBlur = () => {
    setNameFocused(false);
    setNameFilled(sideBarForm.name !== '');
  };

  const handleNameChange = (event) => {
    setNameFilled(event.target.value !== '');
  };

  const toggleSidebar = () => {
    setIsActive(!isActive);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'phone') {
      const phoneRegex = /^[0-9\b]+$/;
      if (value === '' || (phoneRegex.test(value) && value.length <= 10)) {
        setSideBarForm(prevState => ({
          ...prevState,
          [name]: value
        }));
        setPhoneError('');
      }
    } else {
      setSideBarForm(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;
    return phoneRegex.test(phone);
  };

  const handleSidebarFormSubmit = async (e) => {
    e.preventDefault();

    if (!validatePhoneNumber(sideBarForm.phone)) {
      setPhoneError("Invalid phone number! Please enter a 10-digit number.");
      return;
    }

    try {
      const sideBarFormStatus = await axios({
        method: "POST",
        url: "https://websyncbackend.onrender.com/user/formsubmit",
        data: sideBarForm
      });

      const message = sideBarFormStatus.data.message.toString();

      setFormSubmission({
        message: message,
        status: true,
        state: true
      });

      setSideBarForm({
        name: "",
        email: "",
        phone: "",
        message: ""
      });

    } catch (err) {
      console.error(err);
      setFormSubmission({
        message: "Sorry! Unable to take a request at this time.",
        status: true,
        state: false
      });
    }
  };

  return (
    <div className={`sidebar-contact ${isActive ? 'active' : ''}`}>
      <div className="toggle rounded" onClick={toggleSidebar}>
        Quick Enquiry
        <div className={`icon ${isActive ? 'active' : ''}`}></div>
      </div>
      <h2 className='text-light'>Contact Us</h2>
      <div className="scroll h-100 w-100">
        {
          formSubmission.status ?
            <div className='h-100 w-100 d-flex justify-content-center align-items-center'>
              <span className={`alert fw-medium ${formSubmission.state ? "alert-primary" : "alert-danger"}`}>
                {formSubmission.message}
              </span>
            </div> :
            <form onSubmit={handleSidebarFormSubmit}>
              <div className={`form-floating ${nameFocused || nameFilled ? 'focused' : ''}`}>
                <input
                  name='name'
                  value={sideBarForm.name}
                  type="text"
                  className='form-control'
                  id="nameInput"
                  placeholder=' '
                  required
                  onFocus={handleNameFocus}
                  onBlur={handleNameBlur}
                  onChange={(e) => { handleNameChange(e); handleInputChange(e) }}
                />
                <label htmlFor="nameInput">Enter Name</label>
              </div>
              <div className={`form-floating ${nameFocused || nameFilled ? 'focused' : ''}`}>
                <input
                  name='email'
                  value={sideBarForm.email}
                  type="email"
                  className='form-control'
                  id="emailInput"
                  placeholder=' '
                  required
                  onFocus={handleNameFocus}
                  onBlur={handleNameBlur}
                  onChange={(e) => { handleNameChange(e); handleInputChange(e) }}
                />
                <label htmlFor="emailInput">Enter Email</label>
              </div>
              <div className={`form-floating ${nameFocused || nameFilled ? 'focused' : ''}`}>
                <input
                  name='phone'
                  value={sideBarForm.phone}
                  type="tel"
                  className='form-control'
                  id="phoneInput"
                  placeholder=' '
                  required
                  onFocus={handleNameFocus}
                  onBlur={handleNameBlur}
                  onChange={(e) => { handleNameChange(e); handleInputChange(e) }}
                />
                <label htmlFor="phoneInput">Enter Phone</label>
                {phoneError && <small className="text-danger">{phoneError}</small>}
              </div>
              <div className={`form-floating ${nameFocused || nameFilled ? 'focused' : ''}`}>
                <textarea
                  name='message'
                  value={sideBarForm.message}
                  className='form-control'
                  id="messageInput"
                  placeholder=' '
                  required
                  onFocus={handleNameFocus}
                  onBlur={handleNameBlur}
                  onChange={(e) => { handleNameChange(e); handleInputChange(e) }}
                  rows="3"
                ></textarea>
                <label htmlFor="messageInput">Enter Message</label>
              </div>
              <input type="submit" value="Send" />
            </form>
        }
      </div>
    </div>
  );
}

export default Sidebar;
