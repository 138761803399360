import { React, useState } from 'react'
import axios from "axios"

import { useNavigate } from 'react-router-dom';

import "../Styling/dashboard.css"

// import header and footers

const DashminLogin = () => {

    const [nameFocused, setNameFocused] = useState(false);
    const [nameFilled, setNameFilled] = useState(false);

    let [adminForm, setAdminForm] = useState({
        userId: "",
        password: ""
    })

    let [showHide, setShowHide] = useState(false)

    let navigate = useNavigate()

    const handleNameFocus = () => {
        setNameFocused(true);
    };

    const handleNameBlur = () => {
        setNameFocused(false);
        if (nameFilled) {
            setNameFilled(true);
        } else {
            setNameFilled(false);
        }
    };

    const handleNameChange = (event) => {
        setNameFilled(event.target.value !== '');
    };

    let showPassword = () => {
        setShowHide(!showHide)
    }

    let handelAdminLoginInputChange = (event) => {
        const { name, value } = event.target;

        setAdminForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    let amdinLoginFormSubmit = async (event) => {
        event.preventDefault()
        let LoginResult;

        try {

            LoginResult = await axios({
                method: 'post',
                url: 'https://websyncbackend.onrender.com/dashmin',
                data: adminForm
            })

            // console.log(LoginResult)

            if (LoginResult.status === 202) {
                console.log("Login successful!");
                // Extract token from the response
                console.log(LoginResult)
                const token = LoginResult.data.GenratedToken;
                // Set token to localStorage
                localStorage.setItem('token', token);
                // Redirect to dashboard
                navigate('/dashboard');
            }

        } catch (err) {
            alert("Error While Login !")
            console.log("Some Error While Login client : " + err)
        }
    }

    return (
        <>
            <div className='container-fluid admin-login' >
                <div className="container admin-login-main-container">
                    <div className='login-title'>
                        <h1 className='text-light'>Admin Login</h1>
                    </div>
                    <div className='login-form-body'>
                        <form onSubmit={amdinLoginFormSubmit}>
                            <div className='d-flex flex-column gap-4'>
                                <div className="">
                                    <div className={`form-floating ${nameFocused || nameFilled ? 'focused' : ''}`}>
                                        <input
                                            name='userId'
                                            type="text"
                                            className='form-control'
                                            id="nameInput"
                                            placeholder=' '
                                            onFocus={handleNameFocus}
                                            onBlur={handleNameBlur}
                                            onChange={(event) => {
                                                handleNameChange(event)
                                                handelAdminLoginInputChange(event)
                                            }}
                                        />
                                        <label htmlFor="nameInput">User Name</label>
                                    </div>
                                </div>
                                <div className="">
                                    <div className={`form-floating ${nameFocused || nameFilled ? 'focused' : ''}`}>
                                        <input
                                            name='password'
                                            type={showHide ? "text" : "password"}
                                            className='form-control'
                                            id="nameInput"
                                            placeholder=' '
                                            onFocus={handleNameFocus}
                                            onBlur={handleNameBlur}
                                            onChange={(event) => {
                                                handleNameChange(event)
                                                handelAdminLoginInputChange(event)
                                            }}
                                        />
                                        <label htmlFor="nameInput">User Password</label>
                                    </div>
                                </div>
                                <div className="d-flex gap-2">
                                    <button className='btn btn-dark' type='button' onClick={showPassword}>Show Password</button>
                                    <input className='btn btn-primary' type="reset" value="reset" />
                                    <button className='btn btn-success'>login</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashminLogin