import React from 'react'

// importing about images 

import a_img_1 from "../../media/web-development.png"
import a_img_2 from "../../media/bullhorn.png"
import a_img_3 from "../../media/coding.png"

const AboutSection = () => {
    return (
        <>
            {/* about titile */}
            <div className='heros-ending-strip bg-dark text-end' >
                <div className='srtip-band-grp'>
                    <div data-aos="fade-right" className='strip-1'>
                        <span className='d-none d-md-block fw-bolder text-light'>
                            Online Presence ?
                        </span>
                        <span className='d-md-none fw-bolder text-light' style={{ fontSize: "2em" }}>
                            #WSS
                        </span>
                    </div>
                    <div data-aos="fade-right" className='strip-2'>
                        <span className='d-none d-md-block fw-bolder text-light'>
                            Digital Marketing
                        </span>
                    </div>
                    <div data-aos="fade-right" className='strip-3'>
                        <span className='d-none d-md-block fw-bolder text-light'>
                            Professional website
                        </span>
                    </div>
                    <div data-aos="fade-right" className='strip-4'>
                        <span className='fw-bolder text-light'>
                            reach potential audience <mark>#websyncboosters</mark>
                        </span>
                    </div>
                </div>
            </div>
            {/* about tiitle ends */}


            <div className='about_section'>
                <div className='container-fluid'>
                    {/* about title */}

                    <div className='p-5 d-flex justify-content-center align-items-center flex-column'>
                        <span className='fw-bolder fs-2'>About Us</span>
                        <div className='border border-1 w-25 position-relative'>
                            <div className='border border-4 border-primary w-25 position-absolute top-50 start-50 translate-middle'></div>
                        </div>
                    </div>
                    {/* about title */}
                    <div className="container p-md-5">
                        <div className='row justify-content-center'>
                            <div className='col-md-6 col-12 d-flex align-items-center'>
                                <div className='about-imgs-data-h'>
                                    <div className='row'>
                                        <div className='col'>
                                            <div className='about-img-container position-relative'>
                                                <img className='img-fluid w-50 about-image-img' src={a_img_1} alt="" />
                                                <div className='about-img-circle rounded-circle bg-primary position-absolute bottom-0 start-0 translate-middle z-n1 opacity-25' style={{ height: "40px", width: "40px" }}>
                                                </div>
                                                <p className='fw-semibold about-img-text-details'>
                                                    Web <span className='text-primary'>Development</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='about-img-container position-relative'>
                                                <img className='img-fluid w-50 about-image-img' src={a_img_2} alt="" />
                                                <div className='about-img-circle rounded-circle bg-primary position-absolute bottom-0 start-0 translate-middle z-n1 opacity-25' style={{ height: "40px", width: "40px" }}>
                                                </div>
                                                <p className='fw-semibold about-img-text-details'>
                                                    Digital <span className='text-primary'>Marketing</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col'>
                                            <div className='about-img-container position-relative'>
                                                <img className='img-fluid w-50 about-image-img' src={a_img_3} alt="" />
                                                <div className='about-img-circle rounded-circle bg-primary position-absolute bottom-0 start-0 translate-middle z-n1 opacity-25' style={{ height: "40px", width: "40px" }}>
                                                </div>
                                                <p className='fw-semibold about-img-text-details'>
                                                    Professional <span className='text-primary'> Traning</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 col-12'>
                                <h2 className='fw-bolder text-primary'>Our Motto</h2>
                                <p className='' style={{ textAlign: 'justify' }}>
                                    "WebSync Solution" is a forward-thinking web development and digital marketing company located in the city of orange Nagpur. While our journey began recently. Our commitment to delivering exceptional services, comprehensive training, and unmatched value has already made us a go-to partner for businesses across various industry.
                                </p>
                            </div>

                        </div>
                    </div>

                    {/* accordion section for websync solution */}

                    <div className='container-fluid p-4 about-accordion shadow shadow-lg'>
                        <div className="container p-0 p-md-3">
                            <div className='row'>
                                <div className="col-12 col-md-6">
                                    {/* images  */}
                                    <div className='about-some-numbers'>
                                        <div className='row gap-2 text-light justify-content-center'>
                                            <div className="col-12 col-md-5 p-5 bg-dark d-flex flex-column justify-content-center align-items-center">
                                                <span className='fw-bolder text-danger fs-3'>
                                                    100+
                                                </span>
                                                <span className='fw-semi-bold'>Client Served</span>
                                            </div>
                                            <div className="col-12 col-md-6 p-5 bg-dark d-flex flex-column justify-content-center align-items-center">
                                                <span className='fw-bolder text-danger fs-3'>
                                                    25+
                                                </span>
                                                <span className='fw-semi-bold'>Professionals</span>
                                            </div>
                                            <div className="col-12 col-md-6 p-5 bg-dark d-flex flex-column justify-content-center align-items-center">
                                                <span className='fw-bolder text-danger fs-3'>
                                                    #1
                                                </span>
                                                <span className='fw-semi-bold'>
                                                    Best Support
                                                </span>
                                            </div>
                                            <div className="col-12 col-md-5 p-5 bg-dark d-flex flex-column justify-content-center align-items-center">
                                                <span className='fw-bolder text-danger fs-3'>
                                                    24/7
                                                </span>
                                                <span className='fw-semi-bold'>
                                                    Supoport
                                                </span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='my-5 px-4'>
                                        <p className='fw-semibold text-dark'>
                                            "From the heart, we started "webSync Solution" to create, innovate, and inspire. Our passion drives us, and our customers empower us. We're here to make a difference, and we're just getting started. Thank you for being a part of our journey."</p>
                                        <p className='fw-light text-dark'>
                                            "At WebSync Solution, we began with a heartfelt mission to create, innovate, and inspire. Our passion fuels our efforts, and our customers empower our success. We're committed to making a difference, and this is only the beginning. Thank you for being part of our journey."</p>
                                        <p className='text-dark fw-semibold text-end w-100'>
                                            Initiator - Amey Jyotsana Anil Khondekar
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6">
                                    {/* text and accordion */}
                                    <span className='fw-thin fs-md-2 fs-4 text-center text-md-start'>We provide solution to business for creating their <span className='fw-bold text-primary'>online presence #websyncsolution</span></span>

                                    {/* Accordion section starts */}

                                    <div className="accordion" id="accordionExample">
                                        <div className="accordion-item accordion-item-about">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button bg-dark text-light" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <span className='fw-bold fs-3 text-danger'>01</span>Wed Development
                                                </button>
                                            </h2>
                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Elevate your online presence with our expert web development services at WebSync Solution. We specialize in creating dynamic, responsive websites that not only captivate audiences but also drive tangible results for your business. From sleek design to seamless functionality, our team prioritizes user experience, ensuring every visit leaves a lasting impression. Whether you're launching a new venture or revitalizing an existing brand, our tailored solutions empower you to stand out in the digital landscape. Let us turn your vision into reality and propel your online presence to new heights. Discover the difference with WebSync Solution today.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item accordion-item-about">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button bg-dark text-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    <span className='fw-bold fs-3 text-danger'>02</span>Digital Marketing
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Dominate the digital landscape with WebSync Solution's cutting-edge digital marketing services. From SEO to social media, we tailor strategies to enhance your online visibility and engage your audience. Our expert team crafts compelling content that resonates across platforms, driving customer engagement and brand loyalty. With data-driven insights, we ensure your campaigns deliver measurable results. Transform your online presence with WebSync Solution today.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item accordion-item-about">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button bg-dark text-light collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    <span className='fw-bold fs-3 text-danger'>03</span>Mobile Application
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div className="accordion-body">
                                                    Unlock the power of mobile with WebSync Solution's premier app development services. Our team specializes in creating dynamic and intuitive mobile applications that captivate users and drive business growth. From concept to launch, we leverage the latest technologies to deliver seamless experiences across iOS and Android platforms. With a focus on user-centric design and functionality, we ensure your app stands out in a crowded marketplace. Partner with WebSync Solution and turn your mobile vision into reality.
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default AboutSection