import React from 'react'

import { Link } from 'react-router-dom'

import india from "../../media/india.png"

const Footer = () => {

    let CurrentYear = new Date();

    return (
        <>
            <div className='container-fluid footer py-4'>
                <div className="container">
                    <div className='row gap-md-0 gap-5'>
                        <div className='col-12 col-md-2'>
                            <div className='pe-2'>

                                <h4 className='text-light'># <span className='text-primary'>WebSync Solution</span></h4>

                                <div className='short-about-footer pe-3 text-justify text-secondary'>
                                    <span className='text-light'>Join Our Family</span>

                                    <div className='footer-social-list'>
                                        <ul className='list-group'>
                                            <li className='list-group-item px-0'> <i className='fa-brands fa-facebook'></i> WebSync Solution</li>
                                            <li className='list-group-item px-0'> <i className='fa-brands fa-instagram'></i> WebSync Solution</li>
                                            <li className='list-group-item px-0'> <i className='fa-brands fa-youtube'></i> WebSync Solution</li>
                                            <li className='list-group-item px-0'> <i className='fa-brands fa-linkedin'></i> WebSync Solution</li>
                                        </ul>
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className='col-12 col-md'>
                            <div className='px-md-4'>
                                <div className='row d-flex flex-column flex-md-row justify-content-evenly mb-5 gap-3 gap-md-0'>
                                    <div className='col'>
                                        <div className='text-light d-flex flex-column gap-4'>
                                            <div className='d-flex align-items-center gap-3'>
                                                <i className='fa-solid fa-phone text-primary'></i>
                                                <span className=''>+91 97666 96550</span>
                                            </div>
                                            <div className='d-flex align-items-center gap-3'>
                                                <i className='fa-solid fa-envelope text-primary'></i>
                                                <div className=''>
                                                    connect@websycnsolution.com
                                                </div>
                                            </div>
                                            <div className='d-flex align-items-start gap-3'>
                                                <i className='fa-solid fa-location-dot text-primary p-1'></i>
                                                <span className=''>Plot 56/57, shri ram nagar, near uday nagar square, manewada road, nagpur 44034</span>
                                            </div>
                                            <div className='d-flex align-items-center gap-3'>
                                                <img className='img-fluid' src={india} alt="india" style={{ width: "50px" }} />
                                                <span className='fs-3'>INDIA ❤️</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <h4 className='text-light'>Quick <span className='text-primary'>Links</span></h4>
                                        <ul style={{ listStyle: "none", padding: "0" }}>
                                            <li className='cus-items footer-quick-link'><Link className='nav-link' to="#"> <i className='fa-solid fa-angles-right text-danger'></i> Web Development</Link></li>
                                            <li className='cus-items footer-quick-link'><Link className='nav-link' to="#"> <i className='fa-solid fa-angles-right text-danger'></i> Digital Marketing</Link></li>
                                            <li className='cus-items footer-quick-link'><Link className='nav-link' to="#"> <i className='fa-solid fa-angles-right text-danger'></i> Paid Marketing</Link></li>
                                            <li className='cus-items footer-quick-link'><Link className='nav-link' to="#"> <i className='fa-solid fa-angles-right text-danger'></i> Google Business SEO</Link></li>
                                            <li className='cus-items footer-quick-link'><Link className='nav-link' to="#"> <i className='fa-solid fa-angles-right text-danger'></i> Social Media Marketing</Link></li>
                                            <li className='cus-items footer-quick-link'><Link className='nav-link' to="#"> <i className='fa-solid fa-angles-right text-danger'></i> Custom Business Softwares</Link></li>
                                            <li className='cus-items footer-quick-link'><Link className='nav-link' to="#"> <i className='fa-solid fa-angles-right text-danger'></i> Learn Professional Skills</Link></li>
                                            <li className='cus-items footer-quick-link'><Link className='nav-link' to="#"> <i className='fa-solid fa-angles-right text-danger'></i> Work With Us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4' style={{ textAlign: "justify" }}>
                            <h4 className='text-light'>About <span className='text-primary'>Us</span></h4>
                            <span className='d-block my-2 text-secondary'>
                                "WebSync Solution" is a forward-thinking web development and digital marketing company located in the city of orange Nagpur. While our journey began recently. Our commitment to delivering exceptional services, comprehensive training, and unmatched value has already made us a go-to partner for businesses across various industry.
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row bg-primary text-center'>
                    <span className='fw-thin text-light'>All Right Reservred &#169; WebSync Solution @ {CurrentYear.getFullYear()} </span>
                </div>
            </div>
        </>
    )
}

export default Footer