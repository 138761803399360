import React from 'react'

// importing navbar
import Navbar from './sections/Navbar.jsx'
import Footer from './sections/Footer.jsx'
import Sidebar from './sections/Sidebar.jsx'
import SocialMediaSidebar from './sections/SocialMediaSidebar.jsx'
import BlogsGrid from './sections/BlogsGrid.jsx'


const Blogs = () => {
    return (
        <>
            <Navbar />

            <Sidebar />
            {/* social media sidebar */}
            <SocialMediaSidebar />
            {/* heros section */}

            <div className='containert-fluid'>
                <div className="container from-us-content my-4">
                    <div className='recent-post'>
                        <div>
                            <h3 className='fw-bolder'> <span className='text-primary'> Recent </span> Post</h3>
                            <p className='fw-semibold'>
                                Read out blog we upload the case stadies of recent project too also awsome and easy to understand tech concepts
                            </p>
                            {
                                <BlogsGrid />
                            }
                        </div>

                        <div className='post-container'>
                            {/* create a blog array this post here something */}
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Blogs