import React from 'react'
import { Link } from 'react-router-dom'

import "../../vendor/fontawesome/css/all.css"

const SocialMediaSidebar = () => {
    return (
        <>

            {/* <!-- social media sidebar --> */}
            <div className="icon-bar d-flex align-items-center">
                <div className='social-media-bar-content'>
                    <Link href="https://www.facebook.com/AmpexCorporation" className="facebook"><i className="fa-brands fa-facebook"></i></Link>
                    <Link href="#" className="twitter"><i className="fa-brands fa-twitter"></i></Link>
                    <Link href="https://www.instagram.com/ampex_corpration/" className="google"><i className="fa-brands fa-instagram"></i></Link>
                    <Link href="#" className="linkedin"><i className="fa-brands fa-linkedin"></i></Link>
                    <Link href="https://wa.me/918237895726" className="whatsapp"><i className="fa-brands fa-whatsapp"></i></Link>
                </div>
                <div className='social-media-target-button'>
                    <i className="fa-solid fa-chevron-right"></i>
                </div>
            </div>

        </>
    )
}

export default SocialMediaSidebar