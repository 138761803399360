import React, { useEffect } from 'react'

// 
import notsureimg from "../../media/notsureimg.png"

// rotatet spins

const Projects = () => {


    useEffect(() => {
        const rotateTextFunction = () => {
            const text = document.querySelector('.Stext p');
            if (text) {
                text.innerHTML = text.innerText
                    .split("")
                    .map((char, i) => `<span style="transform:rotate(${i * 7.5}deg)">${char}</span>`)
                    .join("");
            }
        };

        rotateTextFunction();

        // Cleanup function if needed
        return () => {
            // Clean up any resources if necessary
        };
    }, []); // Empty dependency array to run only on mount


    return (
        <>
            <div className='container-fluid'>
                <div className="container mt-5">
                    <div className='p-5 d-flex justify-content-center align-items-center flex-column '>
                        <span className='fw-bolder fs-2'>How To Take <span className='text-primary'> Business Online ? </span></span>
                        <div className='border border-1 w-25 position-relative'>
                            <div className='border border-4 border-primary w-25 position-absolute top-50 start-50 translate-middle'></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className="col-12 col-lg-6 web-dev-service">
                            <img className='img-fluid' src={notsureimg} alt="" />
                        </div>
                        <div className="col-12 col-lg-6 text-related-web-dev">
                            <h1>Not sure Where <span className='text-primary fw-bolder text-uppercase'> to Start From ? </span> </h1>
                            <p className='fs-4'>Online business can get really messed up!</p>
                            <p className='fw-bold'>
                                The Best way to start is to <span className='text-primary fw-bolder'> identify your presece on internet </span>
                            </p>
                            <p>
                                Elevate your online presence with WebSync Solution's innovative strategies and captivating designs. Stand out in the digital landscape with tailored solutions designed to engage and inspire your audience.
                            </p>

                            <div className=''>
                                <div className="business-id">
                                    <div className='d-flex align-items-center gap-3'>
                                        <div className='chess-icons'>
                                            <i className=" fa-solid fa-chess-pawn fa-2x"></i>
                                        </div>
                                        <span className='text-primary fw-bold'>The Start Phase</span>
                                    </div>
                                    <p>
                                        Forge your digital footprint with portfolio websites, social media profiles, and Google My Business listings, maximizing visibility and engagement in internet and local area.
                                    </p>
                                </div>
                                <div className="business-id">
                                    <div className='d-flex align-items-center gap-3'>
                                        <div className='chess-icons'>
                                            <i className=" fa-solid fa-chess-knight fa-2x "></i>
                                        </div>
                                        <span className='text-primary fw-bold'>The Spark Phase</span>
                                    </div>
                                    <p>
                                        This phase marks a crucial opportunity for businesses to expand their reach and engage with their audience effectively by social media marketing , SEO and GMB ranking.
                                    </p>
                                </div>
                                <div className="business-id">
                                    <div className='d-flex align-items-center gap-3'>
                                        <div className='chess-icons'>
                                            <i className=" fa-solid fa-chess-queen fa-2x"></i>
                                        </div>
                                        <span className='text-primary fw-bold'>The Drive Phase</span>
                                    </div>
                                    <p>
                                        Strategic marketing campaigns on Google and meta platforms (ex. facebook, instagram) businesses aim to expand their reach and engagement with their target audience.
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid p-3 bg-dark'>
                <div className='container'>
                    <div className='row align-items-center'>
                        <h2 className='text-light'>We Can <span className="text-primary"> Help You Start !</span></h2>
                        <div className="col-12 col-lg-9">
                            <div className='row my-4'>
                                <div className='col-12 col-md-4'>
                                    <h3 className='text-light'>Creating a <span className='text-danger'> Website </span></h3>
                                    <p className='text-light' style={{ textAlign: "start" }}>
                                        "Welcome to We Can Help You Start! We're your go-to destination for website creation. Whether you're envisioning a blog, an online store, or a portfolio, we've got the expertise to bring it to life. Our dedicated team will walk you through every step, from initial design concepts to seamless deployment. Let's collaborate and make your online dreams a reality!"
                                    </p>
                                </div>
                                <div className='col-12 col-md-4'>
                                    <h3 className='text-light'>Creating Social<span className='text-danger'> Pages </span></h3>
                                    <p className='text-light' style={{ textAlign: "justify" }}>
                                        "Welcome to We Can Help You Start! Dive into social media with confidence. Whether it's Facebook, Instagram, or LinkedIn, we'll assist you every step. From crafting engaging content to optimizing your profiles, we're here to elevate your online presence. Let's kickstart your social media journey together!"
                                    </p>
                                </div>
                                <div className='col-12 col-md-4 d-flex flex-column align-items-start gap-2 justify-content-evenly'>

                                    <ul className='summary-list'>
                                        <li className='summary-list-item'>
                                            <span className='text-light'>Business Website</span>
                                        </li>
                                        <li className='summary-list-item'>
                                            <span className='text-light'>Digital Marketing</span>
                                        </li>
                                        <li className='summary-list-item'>
                                            <span className='text-light'>Mobile App Development</span>
                                        </li>
                                        <li className='summary-list-item'>
                                            <span className='text-light'>Desktop Software</span>
                                        </li>
                                        <li className='summary-list-item'>
                                            <span className='text-light'>Billing Solutions</span>
                                        </li>
                                        <li className='summary-list-item'>
                                            <span className='text-light'>Management Solutions</span>
                                        </li>
                                        <li className='summary-list-item'>
                                            <span className='text-light'>Custom API Servers</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-3 position-relative overflow-hidden">
                            {/* Projects */}
                            <div className="circle">
                                <div className="Logo_logo"></div>
                                <div className="Stext">
                                    <p className='text-light'> webSync Solution - Strong & Railable Services -</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Projects