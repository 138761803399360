import React from 'react'
// eslint-disable-next-line

// blog grid import
import BlogsGrid from './BlogsGrid.jsx'

const FromUs = () => {

    return (
        <>
            {/* this component will contain blog to display gallery and learn to code section */}

            <div className='containert-fluid'>
                <div className="container from-us-content my-4">
                    <div className='recent-post'>
                        <div>
                            <h3 className='fw-bolder'> <span className='text-primary'> Recent </span> Post</h3>
                            <p className='fw-semibold'>
                                Read out blog we upload the case stadies of recent project too also awsome and easy to understand tech concepts
                            </p>
                            {
                                <BlogsGrid />
                            }
                        </div>

                        <div className='post-container'>
                            {/* create a blog array this post here something */}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {/* hide courses for development */}
                {/* <Courses /> */}
            </div>
        </>
    )
}

export default FromUs