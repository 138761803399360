import React from 'react'

import Navbar from './sections/Navbar'
import Sidebar from './sections/Sidebar'
import SocialMediaSidebar from './sections/SocialMediaSidebar'
import Footer from './sections/Footer'
import DisplayServiceBlock from './sections/DisplayServiceBlock'
import ContactSection from './sections/ContactSection'

const ServicePage = () => {
    return (
        <>
            <Navbar />
            {/* sidebar section */}
            <Sidebar />
            {/* social media sidebar */}
            <SocialMediaSidebar />
            {/* footer section */}

            {/* Details about services */}
            
            {/* Display The Service in Blocks */}
            <DisplayServiceBlock />

            <ContactSection />

            <Footer />
        </>
    )
}

export default ServicePage