// components/pages/Services.jsx
import React from 'react';

import Navbar from './sections/Navbar';
import Footer from './sections/Footer';
import Sidebar from './sections/Sidebar';
import SocialMediaSidebar from './sections/SocialMediaSidebar';

// importing the content from datasheets
import ServiceContainer from './sections/ServiceContainer';

const Services = () => {

  return (
    <>
      <Navbar />

      <ServiceContainer />

      <Sidebar />

      <SocialMediaSidebar />

      <Footer />

    </>
  );
};

export default Services;
