import React from 'react'

const FAQ = () => {
    return (
        <>
            <section className='FAQ p-md-5'>
                <div className='container-fluid'>
                    <div className='container'>
                        <div className='p-5 d-flex justify-content-center align-items-center flex-column'>
                            <span className='fw-bolder fs-2'>Frequently <span className='extra-efect'> Asked Questions  </span> </span>
                            <div className='border border-1 w-25 position-relative'>
                                <div className='border border-4 border-primary w-25 position-absolute top-50 start-50 translate-middle'></div>
                            </div>
                        </div>
                        <div className="accordion accordion-flush" id="accordionFlushExample">
                            <div className="accordion-item shadow-lg my-3">
                                <h2 className="accordion-header" id="flush-headingOne">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <i className='fa-solid fa-question text-primary mx-3 fa-2x'></i> <span className='fw-regular fs-4'>What are we ?</span>
                                    </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        We are WebSync Solution, a dedicated provider of service like Web Developmennt, Digital Marketing & App Development. Our mission is to. We provide solution to business for creating their online presence
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item shadow-lg my-3">
                                <h2 className="accordion-header" id="flush-headingTwo">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        <i className='fa-solid fa-question text-primary mx-3 fa-2x'></i> <span className='fw-regular fs-4'>Do we offer third-party shipping services ?</span>
                                    </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        Yes, we collaborate with reputable third-party shipping services to ensure your orders are delivered promptly and safely. You can choose from various shipping options at checkout.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item shadow-lg my-3">
                                <h2 className="accordion-header" id="flush-headingThree">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        <i className='fa-solid fa-question text-primary mx-3 fa-2x'></i> <span className='fw-regular fs-4'>How can you contact our customer service team?</span>
                                    </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        You can reach our customer service team via email at [customer service email], phone at [customer service phone number], or through our live chat feature on our website. We're here to help with any questions or concerns you may have.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item shadow-lg my-3">
                                <h2 className="accordion-header" id="flush-headingFour">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree">
                                        <i className='fa-solid fa-question text-primary mx-3 fa-2x'></i> <span className='fw-regular fs-4'>What payment methods do we accept?</span>
                                    </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        We accept a variety of payment methods including major credit cards (Visa, MasterCard, American Express), PayPal, and other secure payment options. You can select your preferred method at checkout.
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-item shadow-lg my-3">
                                <h2 className="accordion-header" id="flush-headingFive">
                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseThree">
                                        <i className='fa-solid fa-question text-primary mx-3 fa-2x'></i> <span className='fw-regular fs-4'>What is our return policy?</span>
                                    </button>
                                </h2>
                                <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                                    <div className="accordion-body">
                                        Our return policy allows you to return products within [number of days] days of receipt. Items must be in their original condition and packaging. Please visit our Return Policy page for detailed instructions on how to initiate a return.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FAQ