import { React } from 'react'

import "../../vendor/fontawesome/css/all.css"

// images 
import heros_img from "../../media/new_new_heros.png"

// sub_sections
import Typewriter from "typewriter-effect";
import { Link } from 'react-router-dom';


const Heros = () => {

    return (
        <>
            <div className='heros-all-container position-relative'>
                <div className='heros-section'>
                    <div className='container-fluid heros-content'>
                        <div className='container'>
                            <div className='row align-items-center text-center text-md-start'>
                                <div className="col-md-6 col-12 py-4 p-md-0">
                                    {/* <h4 className='fw-semibold heros-welcome'>Welcome !</h4> */}
                                    <span className='fs-2'>
                                        {/* <span className='psolution fs-5'>Professional Solution for</span> */}
                                        We Can <span className='text-danger fw-semibold'>Help You With</span>  
                                        <span className='fw-bolder typing-title d-block'>
                                            <Typewriter
                                                options={{
                                                    strings: ['Web Development', 'Digital Marketing', 'SEO | GMB | SMM', 'Wordpress', 'Mobile App'],
                                                    autoStart: true,
                                                    loop: true,
                                                }}
                                            />
                                        </span>
                                    </span>
                                    <p className='text-dark'>
                                        Nagpur 🍊 based <span className='text-danger'> Web Development</span> & <span className='text-danger'>Digital Marketing</span> Company
                                    </p>
                                    <p className='fw-thin fs-2 fst-italic'><span className='Tagline-text'> Synchronizing </span> Business </p>
                                    {/* <p className='fw-thin fs-2 fst-italic'><span className='Tagline-text'> Synchronizing </span> Success</p> */}

                                    <div className='heros-cta'>
                                        <button className='heros-cta'>
                                            <Link to="/about" className='heros-cta-btn'>Learn More</Link>
                                            <svg className="icon" viewBox="0 0 24 24" fill="currentColor">
                                                <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" clipRule="evenodd"></path>
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12 heros-img-section-animation">
                                    <div className='heros-container'>
                                        <img className='img-fluid heros-img ms-auto' src={heros_img} alt="websync heros" />
                                    </div>

                                    {/* <div className='disc-group'>
                                        <div className="disc-1"></div>
                                        <div className="disc-2"></div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <svg className='position-absolute bottom-0 z-n1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#4E4FEB" fill-opacity="0.87" d="M0,320L1440,192L1440,320L0,320Z"></path></svg> */}
                {/* <svg className='position-absolute bottom-0 z-n1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#4E4FEB" fill-opacity="0.87" d="M0,224L1440,320L1440,320L0,320Z"></path></svg> */}
                {/* <svg className='position-absolute bottom-0 z-n1' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#4E4FEB" fill-opacity="1" d="M0,160L40,149.3C80,139,160,117,240,122.7C320,128,400,160,480,192C560,224,640,256,720,266.7C800,277,880,267,960,266.7C1040,267,1120,277,1200,277.3C1280,277,1360,267,1400,261.3L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"></path></svg> */}
            </div>
        </>
    )
}

export default Heros