import React from 'react'

// import icons for revolving text

import html from "../../media/tech-logo/html-5.png"
import css from "../../media/tech-logo/css-3.png"
import js from "../../media/tech-logo/js.png"
import jq from "../../media/tech-logo/jquery.png"
import bootstrap from "../../media/tech-logo/bootstrap.png"
import react from "../../media/tech-logo/react.png"
import python from "../../media/tech-logo/python.png"
import java from "../../media/tech-logo/java.png"
import node from "../../media/tech-logo/node.png"
// import figma from "../../media/tech-logo/figma.png"
import linux from "../../media/tech-logo/linux.png"
import mongoDB from "../../media/tech-logo/mongoDB.png"
import mysql from "../../media/tech-logo/Mysql.png"
import php from "../../media/tech-logo/php.png"
import wordpress from "../../media/tech-logo/wordpress.png"
import laravel from "../../media/tech-logo/laravel.png"

// centred images

import center_img from "../../media/new_heros.png"

// 3 more has to come php wordpress 

const Tech_Stack = () => {
    return (
        <>
            <div className="container-fluid">
                <div className='container my-lg-5'>
                    {/* about title */}
                    <div className='p-1 d-flex justify-content-center align-items-center flex-column'>
                        <span className='fw-bolder fs-2'>Tech Stack</span>
                        <div className='border border-1 w-25 position-relative'>
                            <div className='border border-4 border-primary w-25 position-absolute top-50 start-50 translate-middle'></div>
                        </div>
                    </div>
                </div>

                {/* revolving icon around tech stack */}

                <div className='container-fluid tech-stack-back'>

                    <div className='container tech-stack-container'>
                        <div className='icons-revolving-around'>
                            <div className="tech_stack_main">
                                <div className="tech_item" style={{ animationDelay: "-1.5s" }}>
                                    <img className="tech_stack_img" alt='tech stack images' style={{ animationDelay: "-1.5s" }} src={html} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-3s" }}>
                                    <img className="tech_stack_img" alt='tech stack images' style={{ animationDelay: "-3s" }} src={css} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-4.5s" }}>
                                    <img style={{ animationDelay: "-4.5s" }} className="tech_stack_img" alt='tech stack images' src={js} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-6s" }}>
                                    <img style={{ animationDelay: "-6s" }} className="tech_stack_img" alt='tech stack images' src={jq} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-7.5s" }}>
                                    <img style={{ animationDelay: "-7.5s" }} className="tech_stack_img" alt='tech stack images' src={python} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-9s" }}>
                                    <img style={{ animationDelay: "-9s" }} className="tech_stack_img" alt='tech stack images' src={linux} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-10.5s" }}>
                                    <img style={{ animationDelay: "-10.5s" }} className="tech_stack_img" alt='tech stack images' src={bootstrap} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-12s" }}>
                                    <img style={{ animationDelay: "-12s" }} className="tech_stack_img" alt='tech stack images' src={java} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-13.5s" }}>
                                    <img style={{ animationDelay: "-13.5s" }} className="tech_stack_img" alt='tech stack images' src={react} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-15s" }}>
                                    <img style={{ animationDelay: "-15s" }} className="tech_stack_img" alt='tech stack images' src={node} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-16.5s" }}>
                                    <img style={{ animationDelay: "-16.5s" }} className="tech_stack_img" alt='tech stack images' src={mongoDB} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-18s" }}>
                                    <img style={{ animationDelay: "-18s" }} className="tech_stack_img" alt='tech stack images' src={mysql} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-19.5s" }}>
                                    <img style={{ animationDelay: "-19.5s" }} className="tech_stack_img" alt='tech stack images' src={php} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-21s" }}>
                                    <img style={{ animationDelay: "-21s" }} className="tech_stack_img" alt='tech stack images' src={wordpress} />
                                </div>
                                <div className="tech_item" style={{ animationDelay: "-22.5s" }}>
                                    <img style={{ animationDelay: "-22.5s" }} className="tech_stack_img" alt='tech stack images' src={laravel} />
                                </div>
                            </div>

                        </div>
                        
                        <div className='centred-img'>
                            <img className='img-fluid w-25' src={center_img} alt="" />
                        </div>
                    </div>

                </div>


            </div>
        </>
    )
}

export default Tech_Stack