import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { WebDevelopment, DigitalMarketing, AppDevelopment, WebDevelopmentBlog, DigitalMarketingBlog, AppDevelopmentBlog } from './ServiceMenuItems.js';

// Service Data where all the main service blogs has written

import alwaysimg from "../../media/notsureimg.png"


const DisplayServiceBlock = () => {
    const { servicepage } = useParams();
    const [serviceArray, setServiceArray] = useState([]);
    const [selectedBlog, setSelectedBlog] = useState(null);

    // eslint-disable-next-line
    const serviceCategories = {
        WebDevelopment: { services: WebDevelopment, blog: WebDevelopmentBlog },
        DigitalMarketing: { services: DigitalMarketing, blog: DigitalMarketingBlog },
        AppDevelopment: { services: AppDevelopment, blog: AppDevelopmentBlog },
        // Add more categories as needed
        // selected the blog as we select the service from array page
    };

    useEffect(() => {
        // Update the serviceArray state when servicepage changes
        const selectedService = serviceCategories[servicepage] || {};
        setServiceArray(selectedService.services || []);

        setSelectedBlog(selectedService.blog || null);
    }, [servicepage, serviceCategories]);

    return (
        <>
            <div className='service-block-details d-flex justify-content-center align-items-center'>
                <h3 className='text-light'>{servicepage}</h3>
            </div>

            <div className='container-fluid display-service-blog'>
                <div className="container">
                    {selectedBlog && (
                        <div className="border-0 p-4 my-3">

                            <span className='py-4'>
                                <h2 className='text-primary fw-bolder'>{selectedBlog.blogTitile}</h2>
                            </span>
                            {/* capped images and title para */}

                            <span className='fw-semibold'>{selectedBlog.titilePara}</span>

                            <div className="row my-3 flex-column flex-md-row">
                                <div className="col service-blog-capped-images">
                                    <img className='service-blog-capped-images-1' src={selectedBlog.images.img1} alt="" />
                                </div>
                                <div className="col d-flex flex-column justify-content-center">
                                    <span className='fw-bolder fs-2 py-3 text-primary'>
                                        <span className='text-dark'>What is </span>
                                        {selectedBlog.blogTitile} ?
                                    </span>
                                    <p className=''>{selectedBlog.para1}</p>
                                </div>
                            </div>
                            <p>{selectedBlog.para2}</p>
                            <p>{selectedBlog.para3}</p>

                            <div className="container my-5">
                                <h3 className='text-primary'>{servicepage}</h3>
                                <div className="row justify-content-center">
                                    {
                                        serviceArray.map((element, index) => (

                                            <div className="col-12 col-md-3 p-1">
                                                <div className='card border-0 shadow-lg p-4'>
                                                    <h5 key={index}> {element.ServiceName} </h5>
                                                    <div>
                                                        <Link to={`/${servicepage}/${element.ServiceName}`}><button className='btn btn-primary'>Learn More !</button></Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            {/* quick chat wth professionals */}
                            <div className='container-fluid py-4'>
                                <div className="container quick-contact-with-professionals shadow-lg p-5 rounded-3">
                                    <span className='fw-bolder text-primary fs-4'>Din't find what you were looking for ?</span>
                                    <span className='fw-bolder text-dark fs-1 d-block'>Get a quick and 100% free <span className='text-danger'>professional consultation chat</span>.</span>
                                    <div className="row">
                                        <div className="col d-flex flex-wrap">
                                            <a className='btn btn-primary' href="tel:+91 9766696550"><i className='text-light fa-solid fa-phone'></i>  Contact us now !</a>
                                            <a href="./contact" className='fw-bodler btn fw-bolder'>Request a <span className='text-danger'>Call Back !</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* quick chat wth professionals */}

                            <div className='why-choose-us'>
                                <h4 className='text-primary'>Why choose us ?</h4>
                                <p className='fw-semibold'>{selectedBlog.whyus}</p>
                            </div>

                            <div className='summary-service-section'>
                                <div className="row flex-column flex-md-row">
                                    <div className="col d-flex flex-column align-items-start justify-content-center">
                                        <h2>What we claim !</h2>
                                        <ul className='summary-list'>
                                            {
                                                selectedBlog.summaryPoints.map((listItem) => {
                                                    return (
                                                        <li className='summary-list-item'>
                                                            <span className='fw-semibold text-primary'>{listItem.pointName}</span>: <span>{listItem.pointDisc}</span>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="col">
                                        <img className='w-100 h-100' src={alwaysimg} alt="" />
                                    </div>
                                </div>
                            </div>
                            {/* Add more blog content as needed */}
                        </div>

                    )}
                </div>
            </div>

            <div className="container-fluid">
                <div className="container my-3">
                    <h2>Din't Find the right solution ? <span className='text-primary'>Please Contact Us</span></h2>
                </div>
            </div>
        </>
    );
};

export default DisplayServiceBlock;