import React from 'react';

const PostCase = () => {

    return (
        <>
            <div className='container-fluid'>
                <div className='container'>
                    {/* add a bootstrap courosel for custom paragraphs and testimonials */}
                </div>
            </div>
        </>
    )
}

export default PostCase