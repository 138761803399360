// images for serviceblogimages
import webdevserviceblogimg from "../../media/serviceblogimages/webdevserviceblog.jpg"
import digimarserviceblogimg from "../../media/serviceblogimages/digitalmarketing.png"
import appdevserviceblogimg from "../../media/serviceblogimages/appdevelopment.png"

let WebDevelopment = [
    {
        serviceCat: "WebDevelopment",
        webDevServiceId: 1,
        ServiceName: "Portfolio Website",
        ServiceHerosText: "Build Business to Boot Growth with a shining portfolio website. It serves as a centralized hub where you can display your work, highlight your achievements, and tell your professional story in a compelling way.",
        ServicePackages: {
            service_points: [
                {
                    name: "Number of Pages",
                    details: "Creation of a portfolio website with up to 5 pages, including home, about, portfolio/gallery, contact, and one additional custom page.",
                    techUsed: "HTML, CSS, JavaScript",
                },
                {
                    name: "SEO Optimization",
                    details: "Thorough optimization of website content, meta tags, headings, and images to improve search engine visibility. Submission of sitemap to search engines.",
                    techUsed: "SEO best practices",
                },
                {
                    name: "Responsive Design",
                    details: "Design and development of a responsive website that ensures optimal viewing experience across various devices, including desktops, tablets, and smartphones.",
                    techUsed: "Bootstrap framework",
                },
                {
                    name: "Contact Form",
                    details: "Creation and implementation of a fully functional contact form with fields for name, email, subject, and message, along with validation and error handling.",
                    techUsed: "HTML, CSS, JavaScript",
                },
                {
                    name: "Gallery/Portfolio",
                    details: "Integration of a visually appealing gallery or portfolio section to showcase your work, with options for image or video display, filtering, and navigation.",
                    techUsed: "HTML, CSS, JavaScript",
                },
                {
                    name: "Custom Domain",
                    details: "Assistance in selecting and registering a custom domain name that reflects your brand or identity, along with DNS configuration and management.",
                    techUsed: "Domain registration services",
                },
                {
                    name: "Hosting",
                    details: "Setup and configuration of reliable web hosting services tailored to your website's requirements, including storage, bandwidth, security, and support.",
                    techUsed: "Hosting service provider",
                },
                {
                    name: "Analytics",
                    details: "Integration of robust website analytics tools, such as Google Analytics, to track and analyze visitor behavior, traffic sources, conversions, and other key metrics.",
                    techUsed: "Google Analytics",
                },
            ],
            service_pricing: "10000"
        },
        ServiceDetails: "Having a portfolio website is crucial for anyone looking to showcase their skills and expertise effectively. It serves as a centralized hub where you can display your work, highlight your achievements, and tell your professional story in a compelling way. Whether you're a designer, developer, writer, or any other creative professional, a portfolio website acts as your digital business card, making it easier for potential clients or employers to discover you and understand the value you bring. It allows you to demonstrate your unique style, expertise, and versatility, ultimately helping you stand out in a competitive market. Additionally, a well-crafted portfolio website provides credibility and legitimacy, showing that you're serious about your craft and committed to your professional growth. In today's digital age, where first impressions are often formed online, having a polished portfolio website is essential for making a memorable impact and opening doors to exciting opportunities."
    },
    {
        serviceCat: "WebDevelopment",
        webDevServiceId: 2,
        ServiceName: "eCommerce Website",
        ServiceHerosText: "Elevate your business with an engaging e-commerce website—a central hub to showcase products, highlight achievements, and tell your brand's story effectively.",
        ServicePackages: {
            service_points: [
                {
                    name: "Product Pages Setup",
                    details: "Creation of an e-commerce website with up to 5 product pages, including home, product catalog, individual product pages, cart, and checkout.",
                    techUsed: "HTML, CSS, JavaScript"
                },
                {
                    name: "SEO Optimization",
                    details: "Thorough optimization of website content, meta tags, headings, and images to improve search engine visibility. Submission of sitemap to search engines.",
                    techUsed: "SEO best practices"
                },
                {
                    name: "Responsive Design",
                    details: "Design and development of a responsive website that ensures optimal viewing experience across various devices, including desktops, tablets, and smartphones.",
                    techUsed: "Bootstrap framework"
                },
                {
                    name: "Product Catalog",
                    details: "Integration of a visually appealing product catalog section to display products effectively, with options for filtering, sorting, and navigation.",
                    techUsed: "HTML, CSS, JavaScript"
                },
                {
                    name: "Shopping Cart Functionality",
                    details: "Implementation of a fully functional shopping cart system allowing users to add, remove, and manage items, as well as calculate totals and proceed to checkout.",
                    techUsed: "HTML, CSS, JavaScript"
                },
                {
                    name: "Payment Gateway Integration",
                    details: "Integration of secure payment gateway services to facilitate seamless online transactions, ensuring customer trust and satisfaction.",
                    techUsed: "Payment gateway APIs"
                },
                {
                    name: "Order Management System",
                    details: "Development of an efficient order management system to handle orders, process payments, manage inventory, and track shipments.",
                    techUsed: "Backend technologies (e.g., Node.js, Python)"
                },
                {
                    name: "Analytics",
                    details: "Integration of robust website analytics tools, such as Google Analytics, to track and analyze visitor behavior, traffic sources, conversions, and other key metrics.",
                    techUsed: "Google Analytics"
                }
            ],
            service_pricing: "15000"
        },
        ServiceDetails: "In today's digital marketplace, an eCommerce website is essential for anyone looking to showcase their products and services effectively. It serves as a centralized platform where you can display your offerings, highlight your achievements, and tell your brand story in a compelling way. Whether you're selling fashion, electronics, home goods, or any other products, an eCommerce website acts as your virtual storefront, making it easier for customers to discover you and understand the value you bring. It allows you to showcase your unique products, expertise, and customer service, ultimately helping you stand out in a competitive market. Additionally, a well-crafted eCommerce website provides credibility and legitimacy, showing that you're serious about your business and committed to delivering a seamless shopping experience. In today's digital age, where online shopping is increasingly prevalent, having a polished eCommerce website is essential for making a lasting impression and unlocking growth opportunities."
    },
    {
        serviceCat: "WebDevelopment",
        webDevServiceId: 3,
        ServiceName: "CMS Website",
        ServiceHerosText: "Unlock your online potential with a dynamic CMS website—a versatile platform to publish, manage, and organize content effortlessly.",
        ServicePackages: {
            service_points: [
                {
                    name: "Content Management System",
                    details: "Implementation of a robust content management system (CMS) to empower you to create, edit, and organize website content with ease.",
                    techUsed: "WordPress, Joomla, Drupal, etc."
                },
                {
                    name: "Custom Design",
                    details: "Customization of website design and layout to align with your brand identity and provide a unique user experience.",
                    techUsed: "HTML, CSS, JavaScript"
                },
                {
                    name: "Responsive Design",
                    details: "Development of a responsive website design that adapts seamlessly to various screen sizes and devices for an optimal user experience.",
                    techUsed: "Bootstrap framework"
                },
                {
                    name: "SEO Optimization",
                    details: "Optimization of website content, meta tags, and URL structures for improved search engine visibility and higher rankings.",
                    techUsed: "SEO best practices"
                },
                {
                    name: "Integration of Plugins",
                    details: "Integration of necessary plugins and extensions to enhance website functionality and meet specific business requirements.",
                    techUsed: "CMS plugins/extensions"
                },
                {
                    name: "Hosting and Domain Setup",
                    details: "Assistance in selecting a reliable hosting provider and domain name, along with setup and configuration to ensure smooth website operation.",
                    techUsed: "Hosting service provider, Domain registrar"
                },
                {
                    name: "Maintenance and Support",
                    details: "Ongoing maintenance and technical support to ensure your website remains secure, up-to-date, and optimized for performance.",
                    techUsed: "Technical support services"
                },
                {
                    name: "Training and Documentation",
                    details: "Comprehensive training sessions and documentation to help you understand and manage your CMS website effectively.",
                    techUsed: "Training resources"
                }
            ],
            service_pricing: "12000"
        },
        ServiceDetails: "A CMS website offers the flexibility and scalability needed to adapt to your evolving business needs. Whether you're a small business, nonprofit organization, or enterprise, a CMS website provides a user-friendly interface for managing your online presence efficiently. With features like content editing, publishing workflows, and user management, you can streamline your content creation process and engage your audience effectively. Additionally, a well-designed CMS website ensures a seamless user experience across devices, helping you reach and convert more visitors. By investing in a CMS website, you're investing in the future of your online presence and positioning your business for long-term success."
    },
    {
        serviceCat: "WebDevelopment",
        webDevServiceId: 4,
        ServiceName: "Portfolio + eCommerce",
        ServiceHerosText: "Maximize your online presence with a powerful combination of portfolio and eCommerce functionality—a versatile platform to showcase your work and sell your products/services.",
        ServicePackages: {
            service_points: [
                {
                    name: "Combined Features",
                    details: "Integration of portfolio and eCommerce features to create a comprehensive online platform that showcases your work/portfolio and allows for product/service sales.",
                    techUsed: "HTML, CSS, JavaScript, eCommerce platforms (e.g., WooCommerce)"
                },
                {
                    name: "Custom Design",
                    details: "Customization of website design and layout to align with your brand identity and provide a unique user experience for both portfolio display and eCommerce transactions.",
                    techUsed: "HTML, CSS, JavaScript"
                },
                {
                    name: "Responsive Design",
                    details: "Development of a responsive website design that adapts seamlessly to various screen sizes and devices for an optimal user experience across portfolio and eCommerce sections.",
                    techUsed: "Bootstrap framework"
                },
                {
                    name: "SEO Optimization",
                    details: "Optimization of website content, meta tags, and URL structures for improved search engine visibility and higher rankings, enhancing both portfolio and product/service discoverability.",
                    techUsed: "SEO best practices"
                },
                {
                    name: "Shopping Cart Integration",
                    details: "Integration of a robust shopping cart system with eCommerce functionalities to facilitate seamless online transactions for selling products/services showcased in the portfolio.",
                    techUsed: "HTML, CSS, JavaScript, eCommerce platforms (e.g., WooCommerce)"
                },
                {
                    name: "Payment Gateway Integration",
                    details: "Integration of secure payment gateway services to facilitate safe and convenient online transactions for customers purchasing products/services through the portfolio website.",
                    techUsed: "Payment gateway APIs"
                },
                {
                    name: "Order Management System",
                    details: "Development of an efficient order management system to handle eCommerce transactions, process payments, manage inventory, and track shipments.",
                    techUsed: "Backend technologies (e.g., Node.js, Python)"
                },
                {
                    name: "Portfolio Customization",
                    details: "Customization of the portfolio section to highlight your work effectively and provide visitors with an engaging browsing experience, enhancing overall portfolio visibility.",
                    techUsed: "HTML, CSS, JavaScript"
                }
            ],
            service_pricing: "17000"
        },
        ServiceDetails: "Combining portfolio and eCommerce functionality in one website allows you to leverage the strengths of both platforms to maximize your online presence and business opportunities. With a portfolio + eCommerce website, you can showcase your work/portfolio to potential clients and customers while offering products/services for sale directly through your website. This integrated approach streamlines the user experience and encourages visitors to explore your offerings and make purchases, resulting in increased engagement and conversions. Additionally, a well-designed portfolio + eCommerce website enhances your brand credibility and professionalism, positioning you as a trusted provider of both creative services and tangible products. Invest in a portfolio + eCommerce website to expand your online reach and grow your business effectively."
    },
    {
        serviceCat: "WebDevelopment",
        webDevServiceId: 5,
        ServiceName: "Blogging Website",
        ServiceHerosText: "Share your ideas and insights with the world through a captivating blogging website—a dynamic platform to publish, engage, and inspire.",
        ServicePackages: {
            service_points: [
                {
                    name: "Content Management System",
                    details: "Implementation of a robust content management system (CMS) tailored for blogging purposes, enabling you to create, edit, and organize blog posts effortlessly.",
                    techUsed: "WordPress, Joomla, Drupal, etc."
                },
                {
                    name: "Custom Design",
                    details: "Customization of website design and layout to reflect your personal brand or niche focus, creating a visually appealing and cohesive blogging platform.",
                    techUsed: "HTML, CSS, JavaScript"
                },
                {
                    name: "Responsive Design",
                    details: "Development of a responsive website design that ensures optimal viewing experience across various devices, allowing readers to access your blog content anytime, anywhere.",
                    techUsed: "Bootstrap framework"
                },
                {
                    name: "SEO Optimization",
                    details: "Optimization of blog content, meta tags, and URL structures for improved search engine visibility, attracting more organic traffic to your blog posts.",
                    techUsed: "SEO best practices"
                },
                {
                    name: "Social Media Integration",
                    details: "Integration of social media sharing buttons and widgets to encourage readers to share your blog posts across their social networks, increasing your content's reach and engagement.",
                    techUsed: "Social media APIs"
                },
                {
                    name: "Commenting System",
                    details: "Implementation of a user-friendly commenting system to facilitate reader interaction and discussions on your blog posts, fostering community engagement and loyalty.",
                    techUsed: "Commenting plugins/extensions"
                },
                {
                    name: "Analytics",
                    details: "Integration of website analytics tools to track and analyze visitor behavior, popular content, referral sources, and other key metrics to optimize your blogging strategy.",
                    techUsed: "Analytics platforms (e.g., Google Analytics)"
                },
                {
                    name: "Monetization Options",
                    details: "Exploration of various monetization options for your blog, including display advertising, affiliate marketing, sponsored content, and premium subscriptions, to generate revenue.",
                    techUsed: "Monetization platforms (e.g., Google AdSense, Amazon Associates)"
                }
            ],
            service_pricing: "12000"
        },
        ServiceDetails: "A blogging website is a powerful platform for sharing your ideas, expertise, and experiences with the world. Whether you're a passionate hobbyist, aspiring writer, or professional thought leader, a well-crafted blog allows you to connect with a global audience, build credibility, and establish your authority in your niche. With features like customizable themes, multimedia support, and social media integration, you can create engaging and visually appealing content that resonates with your readers. Additionally, a blogging website provides valuable insights into audience preferences and behavior, helping you refine your content strategy and grow your online presence. Whether your goal is to inform, entertain, or inspire, a blogging website offers endless opportunities to express yourself and make an impact on the world."
    },
    {
        serviceCat: "WebDevelopment",
        webDevServiceId: 6,
        ServiceName: "Custom Application",
        ServiceHerosText: "Empower your business with a tailored custom application—a versatile solution designed to address your unique needs and streamline operations effectively.",
        ServicePackages: {
            service_points: [
                {
                    name: "Requirement Analysis",
                    details: "Thorough analysis of your business requirements and objectives to identify key functionalities and features needed in the custom application.",
                    techUsed: "Business analysis techniques"
                },
                {
                    name: "Custom Development",
                    details: "Development of a custom application tailored to your specific business needs and workflows, ensuring seamless integration with existing systems and processes.",
                    techUsed: "Backend technologies (e.g., Node.js, Python), Frontend frameworks (e.g., React, Angular)"
                },
                {
                    name: "User Interface Design",
                    details: "Design and implementation of a user-friendly interface for the custom application, focusing on usability, accessibility, and visual appeal.",
                    techUsed: "UI/UX design principles, CSS frameworks (e.g., Bootstrap)"
                },
                {
                    name: "Database Integration",
                    details: "Integration of databases and data management systems to store, retrieve, and manipulate business-critical data efficiently and securely.",
                    techUsed: "Database management systems (e.g., MySQL, MongoDB)"
                },
                {
                    name: "Testing and Quality Assurance",
                    details: "Comprehensive testing of the custom application to ensure functionality, performance, security, and compatibility across different devices and browsers.",
                    techUsed: "Testing frameworks (e.g., Jest, Selenium), QA best practices"
                },
                {
                    name: "Deployment and Support",
                    details: "Deployment of the custom application to production environments and ongoing support and maintenance to address any issues, implement updates, and optimize performance.",
                    techUsed: "DevOps practices, Continuous integration/continuous deployment (CI/CD)"
                }
            ],
            service_pricing: "18000"
        },
        ServiceDetails: "A custom application offers a tailored solution to address your unique business needs and challenges. Whether you need a custom CRM, ERP, workflow automation system, or any other application, custom development allows you to build exactly what you need without unnecessary features or limitations. With a custom application, you can streamline your business processes, improve efficiency, and enhance collaboration among teams. Additionally, a well-designed custom application can give you a competitive edge by providing unique capabilities and insights that off-the-shelf solutions cannot offer. Invest in a custom application to unlock new possibilities for your business and achieve your goals more effectively."
    },
    {
        serviceCat: "WebDevelopment",
        webDevServiceId: 7,
        ServiceName: "Landing Pages",
        ServiceHerosText: "Capture leads and drive conversions with high-converting landing pages—a targeted approach to engage visitors and encourage action.",
        ServicePackages: {
            service_points:
                [
                    {
                        name: "Conversion-Oriented Design",
                        details: "Design and optimization of landing pages for maximum conversion rates, focusing on persuasive copywriting, compelling visuals, and clear calls-to-action.",
                        techUsed: "Conversion rate optimization (CRO) techniques, A/B testing"
                    },
                    {
                        name: "Responsive Design",
                        details: "Development of responsive landing page designs that adapt seamlessly to different devices and screen sizes, ensuring a consistent user experience across platforms.",
                        techUsed: "Responsive web design principles, CSS frameworks (e.g., Bootstrap)"
                    },
                    {
                        name: "Lead Capture Forms",
                        details: "Integration of lead capture forms and opt-in mechanisms to collect visitor information and build your email list for targeted marketing campaigns.",
                        techUsed: "Form builders, Email marketing platforms (e.g., Mailchimp)"
                    },
                    {
                        name: "Analytics and Tracking",
                        details: "Implementation of analytics and tracking tools to monitor landing page performance, track user interactions, and optimize conversion funnels for better results.",
                        techUsed: "Web analytics platforms (e.g., Google Analytics), Conversion tracking tools"
                    },
                    {
                        name: "Integration with CRM",
                        details: "Integration of landing pages with customer relationship management (CRM) systems to streamline lead management, nurture leads, and track conversions effectively.",
                        techUsed: "CRM platforms (e.g., Salesforce, HubSpot)"
                    },
                    {
                        name: "Customization and Personalization",
                        details: "Personalization of landing page content and offers based on visitor demographics, behavior, and preferences to increase relevance and engagement.",
                        techUsed: "Personalization algorithms, Dynamic content tools"
                    }
                ],
            service_pricing: "7000"
        },
        ServiceDetails: "Landing pages are a powerful tool for capturing leads, promoting offers, and driving conversions. Whether you're running a marketing campaign, promoting a product launch, or driving traffic to your website, a well-designed landing page can make all the difference in turning visitors into customers. With conversion-oriented design principles and strategic optimization, you can create landing pages that resonate with your target audience and compel them to take action. From lead capture forms to personalized content, each element is carefully crafted to guide visitors through the conversion process and achieve your business objectives. Invest in landing pages to maximize your marketing ROI and unlock the full potential of your digital campaigns."

    }
];

let DigitalMarketing = [
    {
        serviceCat: "DigitalMarketing",
        digiMarServiceId: 1,
        ServiceName: "SERP's Ranking",
        ServiceHerosText: "Boost your online visibility and attract more organic traffic with our SERP's ranking services. Get ahead in search engine results and stay ahead of the competition.",
        ServicePackages: {
            service_points: [
                {
                    name: "Keyword Research",
                    details: "Comprehensive keyword research to identify high-potential keywords and phrases relevant to your business, target audience, and industry niche.",
                    techUsed: "Keyword research tools, Analytics platforms"
                },
                {
                    name: "On-Page Optimization",
                    details: "Optimization of on-page elements, including meta tags, headings, content, and internal linking structure, to improve search engine rankings and user experience.",
                    techUsed: "SEO best practices, Content management systems (e.g., WordPress)"
                },
                {
                    name: "Off-Page SEO",
                    details: "Execution of off-page SEO strategies such as link building, social bookmarking, and influencer outreach to enhance website authority and domain credibility.",
                    techUsed: "Link building tools, Social media platforms"
                },
                {
                    name: "Content Creation",
                    details: "Creation of high-quality, engaging content optimized for search engines and tailored to meet the informational needs and search intent of your target audience.",
                    techUsed: "Content management systems (e.g., WordPress), Content creation tools"
                },
                {
                    name: "SEO Audit",
                    details: "Comprehensive analysis of your website's SEO performance, technical issues, and optimization opportunities to identify areas for improvement and strategic action.",
                    techUsed: "SEO auditing tools, Web analytics platforms"
                }
            ], service_pricing: "15,000 - 30,000"
        },
        ServiceDetails: "Achieving a high ranking in search engine results pages (SERPs) is crucial for increasing online visibility, attracting organic traffic, and driving business growth. Our SERP's ranking services are designed to help you optimize your website for search engines, improve your online presence, and outrank your competitors. From keyword research to content creation and off-page SEO, we offer a comprehensive suite of solutions to boost your search engine rankings and drive sustainable organic traffic to your website."
    },
    {
        serviceCat: "DigitalMarketing",
        digiMarServiceId: 2,
        ServiceName: "Organic Marketing",
        ServiceHerosText: "Harness the power of organic marketing to build brand awareness, engage your audience, and drive meaningful interactions. Grow your online presence naturally and authentically.",
        ServicePackages: {
            service_points: [
                {
                    name: "Content Strategy",
                    details: "Development of a customized content strategy aligned with your brand voice, audience preferences, and marketing objectives to drive organic engagement and conversions.",
                    techUsed: "Content marketing tools, Audience research"
                },
                {
                    name: "Social Media Management",
                    details: "Management of social media accounts to curate and share valuable content, engage with followers, and foster meaningful relationships with your audience across platforms.",
                    techUsed: "Social media management tools, Social media analytics"
                },
                {
                    name: "Influencer Collaboration",
                    details: "Identification and collaboration with relevant influencers and thought leaders to amplify your brand message, reach new audiences, and build trust and credibility.",
                    techUsed: "Influencer marketing platforms, Relationship management tools"
                },
                {
                    name: "Community Building",
                    details: "Creation and nurturing of online communities centered around your brand, industry, or niche to foster engagement, advocacy, and peer-to-peer support among your audience.",
                    techUsed: "Community management platforms, Online forum software"
                },
                {
                    name: "Email Marketing",
                    details: "Development and execution of targeted email campaigns to deliver personalized content, promotions, and updates directly to your subscribers' inboxes, driving engagement and conversions.",
                    techUsed: "Email marketing platforms, Marketing automation software"
                }
            ], service_pricing: "12,000 - 25,000"
        },
        ServiceDetails: "Organic marketing focuses on building authentic connections, nurturing relationships, and delivering value to your audience without relying on paid advertising. It involves strategies such as content marketing, social media management, influencer collaboration, community building, and email marketing to attract, engage, and retain customers over time. By investing in organic marketing, you can establish your brand as a trusted authority, cultivate a loyal following, and drive sustainable growth through genuine interactions and meaningful experiences."
    },
    {
        serviceCat: "DigitalMarketing",
        digiMarServiceId: 3,
        ServiceName: "Paid Marketing",
        ServiceHerosText: "Maximize your reach and drive immediate results with our paid marketing solutions. Target your ideal audience, generate qualified leads, and achieve measurable ROI.",
        ServicePackages: {
            service_points: [
                {
                    name: "PPC Advertising",
                    details: "Creation and management of pay-per-click (PPC) advertising campaigns across search engines, social media platforms, and display networks to drive targeted traffic and conversions.",
                    techUsed: "PPC advertising platforms (e.g., Google Ads, Facebook Ads)"
                },
                {
                    name: "Display Advertising",
                    details: "Design and deployment of visually appealing display ads tailored to your target audience and placed strategically on websites, apps, and other digital platforms to increase brand visibility and engagement.",
                    techUsed: "Display ad networks, Ad design tools"
                },
                {
                    name: "Social Media Advertising",
                    details: "Development and optimization of paid advertising campaigns on social media platforms to reach specific demographics, promote products/services, and drive user engagement, leads, and sales.",
                    techUsed: "Social media advertising platforms (e.g., Facebook Ads Manager, LinkedIn Ads)"
                },
                {
                    name: "Remarketing Campaigns",
                    details: "Implementation of remarketing strategies to re-engage website visitors who have previously interacted with your brand, products, or services across various digital channels, reinforcing brand awareness and encouraging conversions.",
                    techUsed: "Remarketing platforms, Conversion tracking tools"
                },
                {
                    name: "Ad Copywriting",
                    details: "Creation of compelling ad copy and persuasive messaging that resonates with your target audience, communicates value propositions, and drives clicks and conversions.",
                    techUsed: "Copywriting tools, A/B testing"
                }
            ],
            service_pricing: "20,000 - 40,000"
        },
        ServiceDetails: "Paid marketing offers a highly effective way to amplify your brand message, reach new audiences, and drive immediate results. By leveraging paid advertising channels such as PPC, display ads, social media ads, and remarketing campaigns, you can target specific demographics, promote your products/services, and generate qualified leads and conversions. Our paid marketing solutions are designed to help you maximize your advertising budget, achieve measurable ROI, and grow your business through strategic ad placements and compelling messaging."
    },
    {
        serviceCat: "DigitalMarketing",
        digiMarServiceId: 4,
        ServiceName: "Google Ads",
        ServiceHerosText: "Reach potential customers precisely when they're searching for products or services like yours with Google Ads. Get your business noticed and drive relevant traffic to your website.",
        ServicePackages: {
            service_points: [
                {
                    name: "Search Ads",
                    details: "Creation and optimization of text-based search ads that appear on Google search results pages when users search for specific keywords related to your products/services, driving targeted traffic to your website.",
                    techUsed: "Google Ads platform, Keyword research tools"
                },
                {
                    name: "Display Ads",
                    details: "Design and deployment of visually engaging display ads that appear on websites, apps, and other digital platforms within the Google Display Network, increasing brand visibility and driving user engagement.",
                    techUsed: "Google Display Network, Ad creation tools"
                },
                {
                    name: "Shopping Ads",
                    details: "Setup and management of product listing ads (PLAs) that showcase your products with images, prices, and promotional offers on Google search results and partner websites, driving qualified leads and sales.",
                    techUsed: "Google Merchant Center, Product feed optimization tools"
                },
                {
                    name: "Video Ads",
                    details: "Creation and optimization of video ads that appear on YouTube and other Google partner sites, capturing the attention of your target audience with engaging content and driving brand awareness, consideration, and conversions.",
                    techUsed: "YouTube Ads platform, Video editing software"
                },
                {
                    name: "Remarketing Campaigns",
                    details: "Implementation of remarketing strategies to re-engage past visitors to your website or app by showing them relevant ads across the Google Display Network, reminding them of your brand and encouraging them to return and convert.",
                    techUsed: "Google Ads remarketing, Conversion tracking tools"
                }
            ],
            service_pricing: "15,000 - 30,000"
        },
        ServiceDetails: "Google Ads is a powerful advertising platform that allows you to reach potential customers precisely when they're searching for products or services like yours on Google search and partner sites. Whether you're looking to drive website traffic, increase brand awareness, or generate leads and sales, Google Ads offers a variety of ad formats and targeting options to suit your business goals. From search ads and display ads to shopping ads and video ads, our Google Ads services are designed to help you maximize your advertising budget, optimize your campaigns, and achieve tangible results."
    },
    {
        serviceCat: "DigitalMarketing",
        digiMarServiceId: 5,
        ServiceName: "Social Media Marketing",
        ServiceHerosText: "Harness the power of social media to build brand awareness, drive engagement, and grow your audience. Reach your target customers where they spend their time online.",
        ServicePackages: {
            service_points: [
                {
                    name: "Social Media Strategy",
                    details: "Development of a comprehensive social media strategy tailored to your brand identity, audience demographics, and marketing objectives to guide your social media activities and campaigns.",
                    techUsed: "Social media analytics, Competitor analysis tools"
                },
                {
                    name: "Content Creation & Curation",
                    details: "Creation and curation of engaging and relevant content (e.g., posts, images, videos, stories) that resonates with your audience, showcases your brand personality, and drives user engagement and interactions.",
                    techUsed: "Content creation tools, Stock image/video libraries"
                },
                {
                    name: "Community Management",
                    details: "Active management and moderation of your social media communities to foster meaningful interactions, respond to comments/messages, address customer inquiries/complaints, and build relationships with your followers.",
                    techUsed: "Social media management tools, Customer relationship management (CRM) software"
                },
                {
                    name: "Influencer Partnerships",
                    details: "Identification and collaboration with relevant influencers and content creators to amplify your brand message, reach new audiences, and build trust and credibility through authentic endorsements and partnerships.",
                    techUsed: "Influencer marketing platforms, Relationship management tools"
                },
                {
                    name: "Social Media Advertising",
                    details: "Creation and management of paid advertising campaigns on social media platforms to target specific demographics, promote your products/services, and drive user engagement, leads, and conversions.",
                    techUsed: "Social media advertising platforms (e.g., Facebook Ads, Instagram Ads)"
                }
            ],
            service_pricing: "15,000 - 30,000"
        },
        ServiceDetails: "Social media marketing offers a powerful way to connect with your audience, build brand awareness, and drive engagement and conversions on platforms like Facebook, Instagram, Twitter, LinkedIn, and more. From developing a social media strategy to creating compelling content, managing your online communities, partnering with influencers, and running targeted ad campaigns, our social media marketing services are designed to help you leverage the full potential of social media to achieve your business goals and grow your brand presence online."
    },
    {
        serviceCat: "DigitalMarketing",
        digiMarServiceId: 6,
        ServiceName: "Local SEO",
        ServiceHerosText: "Optimize your online presence for local search and attract more customers in your area. Dominate local search results and drive foot traffic to your physical locations.",
        ServicePackages: {
            service_points: [
                {
                    name: "Local Keyword Research",
                    details: "Identification of high-potential keywords with local intent (e.g., 'near me' searches) relevant to your business, location, and target audience to optimize your website and content for local search.",
                    techUsed: "Keyword research tools, Local SEO software"
                },
                {
                    name: "Google My Business Optimization",
                    details: "Optimization of your Google My Business (GMB) profile with accurate business information, compelling descriptions, high-quality images, and positive customer reviews to improve visibility in local search results and Google Maps.",
                    techUsed: "Google My Business dashboard, Review management tools"
                },
                {
                    name: "Local Citations & Directories",
                    details: "Creation and management of consistent and accurate business citations and directory listings across relevant online platforms (e.g., Yelp, Yellow Pages, TripAdvisor) to enhance local search visibility and credibility.",
                    techUsed: "Local citation tools, Directory submission services"
                },
                {
                    name: "Localized Content Creation",
                    details: "Development of localized content (e.g., blog posts, landing pages, FAQs) optimized for local keywords and tailored to address the needs, interests, and concerns of your target audience in specific geographic areas.",
                    techUsed: "Content management systems (CMS), Localized SEO tools"
                },
                {
                    name: "Review Management",
                    details: "Monitoring and management of online reviews and ratings across various review sites and platforms to solicit feedback, respond to customer reviews (both positive and negative), and maintain a positive online reputation.",
                    techUsed: "Review management software, Sentiment analysis tools"
                }
            ],
            service_pricing: "12,000 - 25,000"
        },
        ServiceDetails: "Local SEO is essential for brick-and-mortar businesses looking to attract nearby customers and drive foot traffic to their physical locations. By optimizing your online presence for local search, you can improve your visibility in local search results, Google Maps, and other location-based services, making it easier for potential customers to find and connect with your business. Our local SEO services include local keyword research, Google My Business optimization, local citations and directories, localized content creation, and review management to help you dominate local search results and grow your customer base in your target areas."
    }
];

let AppDevelopment = [
    {
        serviceCat: "AppDevelopment",
        appDevServiceId: 1,
        ServiceName: "Custom Mobile App",
        ServiceHerosText: "Craft a tailored mobile app to elevate your business presence and engage your audience effectively.",
        ServicePackages: {
            service_points: [
                {
                    name: "Basic Package",
                    details: "Includes essential features for a basic mobile app, such as user authentication, push notifications, and basic UI/UX design.",
                    techUsed: "React Native, Firebase",
                },
                {
                    name: "Standard Package",
                    details: "Enhanced package with additional features like in-app purchases, social media integration, and advanced analytics.",
                    techUsed: "React Native, Firebase, Redux",
                },
                {
                    name: "Premium Package",
                    details: "Comprehensive package with advanced functionalities, custom UI/UX design, backend integration, and ongoing support.",
                    techUsed: "React Native, Node.js, MongoDB, AWS",
                }
            ],
            service_pricing: "15,000 - 25,000"
        },
        ServiceDetails: "A custom mobile app is essential for businesses looking to connect with customers on the go. Whether you're a startup, small business, or enterprise, a mobile app allows you to engage your audience effectively, streamline processes, and drive revenue. From user-friendly interfaces to seamless navigation, a well-designed mobile app enhances user experience and strengthens brand loyalty. With features like push notifications, in-app purchases, and social media integration, you can keep users informed, drive conversions, and foster community engagement. Invest in a custom mobile app to unlock new opportunities and stay ahead in today's competitive market."
    },
    {
        serviceCat: "AppDevelopment",
        appDevServiceId: 2,
        ServiceName: "API Calling App",
        ServiceHerosText: "Build an API calling app to streamline data exchange and enhance connectivity between different software systems.",
        ServicePackages: {
            service_points: [
                {
                    name: "Basic Package",
                    details: "Includes essential features for API calling, such as endpoint configuration, request methods, and response handling.",
                    techUsed: "Node.js, Express, RESTful APIs",
                },
                {
                    name: "Standard Package",
                    details: "Enhanced package with additional features like authentication, data validation, error handling, and API documentation.",
                    techUsed: "Node.js, Express, JWT, Swagger",
                },
                {
                    name: "Premium Package",
                    details: "Comprehensive package with advanced functionalities, custom integrations, performance optimization, and ongoing support.",
                    techUsed: "Node.js, Express, GraphQL, Docker, Kubernetes",
                }
            ],
            service_pricing: "5,000 - 12,000"
        },
        ServiceDetails: "An API calling app acts as a bridge between different software systems, allowing seamless communication and data exchange. Whether you're building a web application, mobile app, or IoT device, APIs play a crucial role in enabling connectivity and integration. With features like authentication, error handling, and documentation, you can ensure secure and efficient data transfer between systems. Invest in an API calling app to streamline your development process, enhance connectivity, and deliver seamless user experiences."
    },
    {
        serviceCat: "AppDevelopment",
        appDevServiceId: 3,
        ServiceName: "eCommerce Store",
        ServiceHerosText: "Establish a robust eCommerce store to showcase your products/services and drive online sales effectively.",
        ServicePackages: {
            service_points: [
                {
                    name: "Basic Package",
                    details: "Includes essential features for setting up an eCommerce store, such as product listings, shopping cart, and checkout functionality.",
                    techUsed: "React, Node.js, MongoDB",
                },
                {
                    name: "Standard Package",
                    details: "Enhanced package with additional features like payment gateway integration, order management, and customer accounts.",
                    techUsed: "React, Node.js, MongoDB, Stripe",
                },
                {
                    name: "Premium Package",
                    details: "Comprehensive package with advanced functionalities, custom design, SEO optimization, and ongoing maintenance.",
                    techUsed: "React, Node.js, MongoDB, Redux, Elasticsearch",
                }
            ],
            service_pricing: "15,000 - 25,000"
        },
        ServiceDetails: "An eCommerce store is essential for businesses looking to expand their reach and drive online sales. Whether you're a retail store, boutique, or digital marketplace, an eCommerce platform allows you to showcase your products/services, process transactions, and manage orders efficiently. With features like product listings, shopping cart, and payment gateway integration, you can provide a seamless shopping experience for your customers. Invest in an eCommerce store to tap into the growing online market and unlock new opportunities for growth and revenue."
    },
    {
        serviceCat: "AppDevelopment",
        appDevServiceId: 4,
        ServiceName: "iOS & Andriod Apps",
        ServiceHerosText: "Develop a phone book app to organize contacts, streamline communication, and enhance productivity on mobile devices.",
        ServicePackages: {
            service_points: [
                {
                    name: "Basic Package",
                    details: "Includes essential features for managing contacts, such as contact creation, editing, and deletion.",
                    techUsed: "React Native, AsyncStorage",
                },
                {
                    name: "Standard Package",
                    details: "Enhanced package with additional features like contact synchronization, search functionality, and backup/restore options.",
                    techUsed: "React Native, Firebase, Redux",
                },
                {
                    name: "Premium Package",
                    details: "Comprehensive package with advanced functionalities, custom design, cloud storage integration, and security features.",
                    techUsed: "React Native, AWS Amplify, GraphQL, Encryption",
                }
            ],
            service_pricing: "5,000 - 25,000"
        },
        ServiceDetails: "A phone book app is a handy tool for organizing contacts, managing relationships, and staying connected on the go. Whether you're a professional, entrepreneur, or individual user, a phone book app helps you streamline communication and enhance productivity on your mobile device. With features like contact management, synchronization, and search functionality, you can access your contacts anytime, anywhere, and never miss an important connection. Invest in a phone book app to simplify your life, stay organized, and make the most of your mobile experience."
    }
];



// data blog for web development/digital marketing/app development

let WebDevelopmentBlog = {

    blogTitile: "Web Developments",
    titilePara: "Well getting a website can be best thing a for many reasons like gaining the trust of potential customers, build a dedicated space to lunch or present something like new services/products.",
    para1: "Establishing a website offers numerous benefits that can significantly enhance your online presence and business prospects. Firstly, having a website provides your business with a digital storefront that operates 24/7, allowing potential customers to access information about your products or services at any time. This accessibility not only increases convenience for your audience but also expands your reach beyond geographical boundaries, enabling you to target a global audience and potentially attract customers from different regions.",
    para2: "Secondly, a well-designed website serves as a powerful marketing tool, enabling you to showcase your brand identity, highlight your unique selling propositions, and engage with your target audience through compelling content, visuals, and interactive features. By optimizing your website for search engines (SEO), you can improve its visibility on search engine results pages (SERPs), driving organic traffic and increasing your chances of being discovered by individuals actively seeking solutions or information related to your offerings.",
    para3: "Thirdly, a website fosters credibility and trustworthiness for your brand, particularly in today's digital age where consumers rely heavily on online information and reviews to make purchasing decisions. A professionally designed website with clear navigation, user-friendly interface, secure payment gateways, and customer support channels instills confidence in potential customers, making them more likely to engage with your business, make purchases, and even recommend your products or services to others.",
    whyus: "Web Sync Solutions is a dynamic provider of comprehensive web development services tailored to meet the diverse needs of businesses across various industries. With a dedicated team of experienced professionals, Web Sync Solutions offers a wide range of services, including website design, development, and maintenance, as well as e-commerce solutions, custom web application development, and content management system (CMS) integration. At Web Sync Solutions, we prioritize client satisfaction and strive to deliver innovative and user-centric solutions that align with our clients' unique goals and objectives. Our commitment to excellence, coupled with our extensive expertise in the latest web technologies and trends, enables us to create robust, scalable, and visually appealing websites and applications that enhance our clients' online presence and drive tangible results. Whether you're a startup looking to establish your brand or a large enterprise seeking to optimize your digital strategy, Web Sync Solutions is your trusted partner for all your web development needs. With a focus on quality, reliability, and innovation, we are dedicated to helping businesses thrive in the ever-evolving digital landscape. ",

    summaryPoints: [
        {
            pointName: "Comprehensive Web Development Services",
            pointDisc: "Web Sync Solutions offers a wide array of web development services, including website design, development, maintenance, e-commerce solutions, custom web application development, and CMS integration."
        },
        {
            pointName: "Client-Centric Approach",
            pointDisc: "The company prioritizes client satisfaction and strives to deliver solutions that align with clients' unique goals and objectives."
        },
        {
            pointName: "Expertise and Innovation",
            pointDisc: "With a dedicated team of experienced professionals, Web Sync Solutions leverages the latest web technologies and trends to create innovative, user-centric solutions."
        },
        {
            pointName: "Robust and Scalable Solutions",
            pointDisc: "The company specializes in developing robust, scalable, and visually appealing websites and applications that enhance clients' online presence and drive tangible results."
        },
        {
            pointName: "Trusted Partner",
            pointDisc: "Whether you're a startup or a large enterprise, Web Sync Solutions serves as a trusted partner for all web development needs, focusing on quality, reliability, and innovation."
        }
    ],

    lastPara: "Overall, Web Sync Solutions stands out as a dynamic and reliable provider of web development services, committed to helping businesses thrive in the digital landscape.",

    images: {
        img1: webdevserviceblogimg
    }

}

let DigitalMarketingBlog = {

    blogTitile: "Digital Marketing",
    titilePara: "Having a strong digital marketing presence can significantly benefit your business for several reasons. It enhances credibility with potential customers, provides a dedicated platform to launch or showcase new products/services, and fosters greater trust among your audience.",
    para1: "Developing a robust digital marketing strategy offers myriad advantages that can greatly amplify your online visibility and business potential. Firstly, leveraging digital platforms provides your brand with an ever-present virtual storefront that operates around the clock, granting potential customers continuous access to information about your offerings. This accessibility not only enhances convenience for your target audience but also broadens your outreach beyond geographical constraints, enabling you to engage with a global audience and potentially attract clientele from diverse regions.",
    para2: "Secondly, a meticulously crafted digital marketing strategy positions your website as a potent marketing asset, empowering you to articulate your brand identity, accentuate your distinctive value propositions, and interact with your target demographic through captivating content, imagery, and interactive elements. Through strategic search engine optimization (SEO) initiatives, your website can ascend in search engine rankings, bolstering its prominence on search engine results pages (SERPs), thus amplifying organic traffic and heightening the likelihood of exposure to individuals actively seeking solutions or insights pertaining to your products or services.",
    para3: "Thirdly, an effective digital marketing strategy enhances credibility and trustworthiness for your brand, especially in today's digital era where consumers heavily depend on online information and reviews to guide their purchasing decisions. By employing professional content creation, engaging social media presence, targeted advertising campaigns, and responsive customer support channels, your brand gains credibility, encouraging potential customers to interact with your business, make purchases, and advocate for your products or services to others.",
    whyus: "We, at Web Sync Solutions, are committed to delivering dynamic and tailored digital marketing services that cater to businesses across various industries. With a team of skilled professionals, we offer a comprehensive suite of solutions including strategic planning, content creation, social media management, search engine optimization (SEO), pay-per-click (PPC) advertising, email marketing, and analytics tracking. Our dedication to client satisfaction propels us to provide innovative and results-driven strategies aligned with our clients' unique objectives. Leveraging our expertise in the latest digital marketing technologies and trends, we craft campaigns that enhance our clients' online presence and deliver measurable outcomes. Whether you're a startup seeking to establish your brand or a large enterprise aiming to optimize your digital strategy, Web Sync Solutions is your trusted partner for all digital marketing needs. With a focus on quality, reliability, and innovation, we are committed to empowering businesses to thrive in the dynamic digital landscape.",

    summaryPoints: [
        {
            pointName: "Tailored Digital Marketing Solutions",
            pointDisc: "Web Sync Solutions provides an extensive range of digital marketing services tailored to meet the unique needs of businesses. Our offerings include strategic planning, content creation, social media management, search engine optimization (SEO), pay-per-click (PPC) advertising, email marketing, and analytics tracking."
        },
        {
            pointName: "Customer-Centric Strategy",
            pointDisc: "At Web Sync Solutions, we prioritize client satisfaction and aim to deliver digital marketing solutions that perfectly align with our clients' distinctive goals and objectives."
        },
        {
            pointName: "Expertise and Innovation",
            pointDisc: "With a dedicated team of seasoned professionals, Web Sync Solutions harnesses cutting-edge digital marketing technologies and trends to develop innovative, client-focused solutions."
        },
        {
            pointName: "Robust and Scalable Solutions",
            pointDisc: "The company specializes in developing robust, scalable, and visually appealing websites and applications that enhance clients' online presence and drive tangible results."
        },
        {
            pointName: "Dynamic and Scalable Strategies",
            pointDisc: "Web Sync Solutions excels in crafting dynamic, scalable, and visually captivating digital marketing campaigns that elevate clients' online visibility and achieve measurable outcomes."
        }
    ],

    lastPara: "In essence, Web Sync Solutions is acknowledged as a dynamic and reliable leader in digital marketing services, committed to empowering businesses for success in the digital realm.",

    images: {
        // change this for digital markteing images
        img1: digimarserviceblogimg
    }

}

let AppDevelopmentBlog = {
    blogTitile: "App Development",
    titilePara: "Having a robust app development strategy can significantly benefit your business for several reasons. It enhances user engagement, provides a convenient platform to showcase your products or services, and fosters brand loyalty among your audience.",
    para1: "Developing a comprehensive app development strategy offers numerous advantages that can greatly enhance your business's digital presence and potential. Firstly, having a mobile application provides your brand with a dedicated space on users' devices, enabling seamless access to your offerings anytime, anywhere. This accessibility not only improves user experience but also expands your reach beyond geographical boundaries, allowing you to connect with a global audience and potentially attract users from diverse demographics.",
    para2: "Secondly, a well-designed mobile application serves as a powerful marketing tool, enabling you to showcase your brand identity, highlight key features, and engage with your target audience through personalized content, interactive elements, and push notifications. By optimizing your app for app store visibility and user experience, you can increase downloads and user retention, driving organic growth and expanding your customer base.",
    para3: "Thirdly, an effective app development strategy enhances credibility and trustworthiness for your brand, particularly in today's mobile-centric world where consumers rely heavily on apps for various tasks and activities. A professionally designed and reliable app with intuitive navigation, seamless functionality, and responsive customer support instills confidence in users, making them more likely to engage with your business, make purchases, and recommend your app to others.",
    whyus: "We, at Web Sync Solutions, are committed to delivering dynamic and tailored app development services that cater to businesses across various industries. With a team of skilled professionals, we offer a comprehensive suite of solutions including app design, development, testing, deployment, and maintenance. Our dedication to client satisfaction drives us to provide innovative and user-centric solutions aligned with our clients' unique objectives. Leveraging our expertise in the latest app development technologies and trends, we craft apps that enhance user experience and deliver tangible results. Whether you're a startup looking to launch your app or a large enterprise aiming to optimize your mobile strategy, Web Sync Solutions is your trusted partner for all app development needs. With a focus on quality, reliability, and innovation, we are committed to empowering businesses to thrive in the dynamic digital landscape.",

    summaryPoints: [
        {
            pointName: "Tailored App Development Solutions",
            pointDisc: "Web Sync Solutions provides an extensive range of app development services tailored to meet the unique needs of businesses. Our offerings include app design, development, testing, deployment, and maintenance."
        },
        {
            pointName: "Customer-Centric Approach",
            pointDisc: " At Web Sync Solutions, we prioritize client satisfaction and aim to deliver app development solutions that perfectly align with our clients' distinctive goals and objectives."
        },
        {
            pointName: "Expertise and Innovation",
            pointDisc: "With a dedicated team of seasoned professionals, Web Sync Solutions harnesses cutting-edge app development technologies and trends to develop innovative, user-focused solutions."
        },
        {
            pointName: "Robust and Scalable Solutions",
            pointDisc: "The company specializes in developing robust, scalable, and visually appealing mobile applications that enhance user experience and drive tangible results."
        },
        {
            pointName: "Dynamic and Scalable Strategies",
            pointDisc: "Web Sync Solutions excels in crafting dynamic, scalable, and user-centric app development strategies that elevate user engagement and achieve measurable outcomes."
        }
    ],

    lastPara: "In essence, Web Sync Solutions is acknowledged as a dynamic and reliable leader in app development services, committed to empowering businesses for success in the digital realm.",

    images: {
        // change this for digital markteing images
        img1: appdevserviceblogimg
    }
}


// sending service section along with the blogs then indentifying what blog is selected then pass or selecting the service blog based on that

export { WebDevelopment, DigitalMarketing, AppDevelopment, WebDevelopmentBlog, DigitalMarketingBlog, AppDevelopmentBlog }