import React from 'react'

// importing navbar
import Navbar from './sections/Navbar.jsx'
import Footer from './sections/Footer.jsx'
import Sidebar from './sections/Sidebar.jsx'
import SocialMediaSidebar from './sections/SocialMediaSidebar.jsx'
import AboutSection from './sections/AboutSection.jsx'
import SolutionFor from './sections/SolutionFor.jsx'
import TechStack from './sections/TechStack.jsx'
import ContactSection from './sections/ContactSection.jsx'
import Course from './sections/Course.jsx'

const About = () => {
    return (
        <>
            <Navbar />

            <Sidebar />
            {/* social media sidebar */}
            <SocialMediaSidebar />

            {/* want to become a full stack developer section */}

            <Course />

            <AboutSection />
            {/* solution for */}

            <SolutionFor />

            <TechStack />

            <ContactSection />

            <Footer />
        </>
    )
}

export default About