import React, { useState } from 'react'
import axios from "axios"
import { Link } from 'react-router-dom'


// fetch blog data from backend 

const BlogsGrid = () => {

    let [blogs, setBlogs] = useState([])

    let fetchBlogs = async (e) => {
        try {

            let getBlogs = await axios.get("https://websyncbackend.onrender.com/blog-posts")

            setBlogs(getBlogs.data)

        } catch (err) {

            console.log("Error While Getting Blogs : ", err)

        }
    }

    fetchBlogs()

    return (
        <>
            <div className="container-fluid">
                <div className="row gap-1 gap-lg-0">
                    {
                        blogs.map((post) => (

                            <div key={post._id} className='col-12 col-md-3'>

                                <div className="blog-post card py-2 border-0 shadow rounded-0">
                                    
                                    <div className='p-2'>
                                        <span className='card-title fw-bold'>{post.title}</span>
                                    </div>

                                    <img className='img-fluid blog-grid-thumbnail' src={`https://websyncbackend.onrender.com${post.thumbnailUrl}`} alt={post.title} />

                                    <div className='p-2 d-flex justify-content-between'>
                                        <span><span className='fw-bold'>At:</span> {post.postingDate}</span>
                                        <span className='fw-semibold'>Author: Admin</span>
                                    </div>
                                    <div className='p-2'>
                                        <Link className='btn btn-primary' to={`/blogs/${post.title}/`}>
                                            Read Full Blog
                                        </Link>
                                    </div>
                                </div>

                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default BlogsGrid