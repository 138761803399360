import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { WebDevelopment, DigitalMarketing, AppDevelopment } from './ServiceMenuItems.js';
import ContactSection from "./ContactSection.jsx";
import axios from 'axios';

const serviceCategories = {
    WebDevelopment: WebDevelopment,
    DigitalMarketing: DigitalMarketing,
    AppDevelopment: AppDevelopment,
};

const ServiceContainer = () => {
    const { servicescat, service } = useParams();
    const [loadService, setLoadService] = useState({});
    const [selectedServicePack, setSelectedServicePack] = useState(''); 
    const [showQuoteForm, setShowQuoteForm] = useState(false); 

    useEffect(() => {
        const serviceArray = serviceCategories[servicescat] || [];
        const getService = serviceArray.find(element => element.ServiceName === service) || {};
        setLoadService(getService);
    }, [servicescat, service]);

    const toggleQuoteForm = (servicePackName) => {
        setSelectedServicePack(servicePackName); 
        setShowQuoteForm(prevState => !prevState);
    };

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        section.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <>
            <div className="container-fluid service-page-main d-flex flex-column justify-content-center align-items-start">
                <div className="container">
                    <div className='heros-text'>
                        {/* service banner logo */}
                        <h5 className='fw-bolder text-primary p-3 bg-dark d-inline-block rounded-3'>{servicescat}</h5>
                        <h1 className='text-light'>{service}</h1>
                        <p className='text-light'>
                            {loadService.ServiceHerosText}
                        </p>
                        <div className='service-hero-cta'>
                            <button className='btn btn-primary d-flex justify-content-center align-items-center' onClick={() => toggleQuoteForm(loadService.ServiceName)}>
                                Connect With Professional <i className="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid bg-dark text-light shadow">
                <div className='container'>
                    <div className='row'>
                        <div className="col p-3 service-bar-links" onClick={() => scrollToSection("overview")}>
                            <span className='fw-bold'>Overview <i className="fa-solid fa-caret-down"></i></span>
                        </div>
                        <div className="col p-3 service-bar-links">
                            <span className='fw-bold'>Testimonial <i className="fa-solid fa-caret-down"></i></span>
                        </div>
                        <div className="col p-3 service-bar-links">
                            <span className='fw-bold'>Services <i className="fa-solid fa-caret-down"></i></span>
                        </div>
                        <div className="col bg-primary text-light p-3 service-bar-links" onClick={() => scrollToSection("quick-contact")}>
                            <span className='fw-bold'>Quick Contact !</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid my-3'>
                <div className='container'>
                    <div className="my-3">
                        <h1 className='text-primary'>{service}</h1>
                    </div>
                    <div className=''>
                        <DisplayService loadService={loadService} toggleQuoteForm={toggleQuoteForm} />
                    </div>
                </div>
            </div>

            <div className='container-fluid my-5'>
                <div className="container bg-dark rounded text-light p-3">
                    <div className='service-pack-footer'>
                        <div className="row">
                            <div className="col-12 col-md-3 mb-5 m-md-0">
                                <span className='py-3 px-3 badge rounded-pill bg-primary mb-3 fs-6'>Cost Effective Solutions</span>
                                <h4>
                                    Customized Packages <span className='text-danger'>Contact Us Now !</span>
                                </h4>
                            </div>
                            <div className="col-12 col-md-9">
                                <div className='row'>
                                    <div className='col-12 col-md my-2 my-md-0 d-flex flex-row flex-md-column aign-items-center justify-content-center gap-2'>
                                        <div className="row gap-md-2">
                                            <div className="col-2 col-md-12 d-flex flex-column justify-content-center">
                                                <i className='text-danger fa-solid fa-star fa-2x'></i>
                                            </div>
                                            <div className="col-10 col-md-12">
                                                <h5 className='text-light'>Best Services</h5>
                                                <p className='text-secondary'>
                                                    Delivering unparalleled business solutions.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md my-2 my-md-0 d-flex flex-row flex-md-column aign-items-center justify-content-center gap-2'>
                                        <div className="row gap-md-2">
                                            <div className="col-2 col-md-12 d-flex flex-column justify-content-center">
                                                <i className='text-danger fa-solid fa-sack-dollar fa-2x'></i>
                                            </div>
                                            <div className="col-10 col-md-12">
                                                <h5 className='text-light'>Cost Effective Solution</h5>
                                                <p className='text-secondary'>
                                                    We specialize in providing cost-effective solutions.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md my-2 my-md-0 d-flex flex-row flex-md-column aign-items-center justify-content-center gap-2'>
                                        <div className="row gap-md-2">
                                            <div className="col-2 col-md-12 d-flex flex-column justify-content-center">
                                                <i className='text-danger fa-solid fa-chart-pie fa-2x'></i>
                                            </div>
                                            <div className="col-10 col-md-12">
                                                <h5 className='text-light'>Return On Investment</h5>
                                                <p className='text-secondary'>
                                                    Maximizing your ROI is our priority.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-md my-2 my-md-0 d-flex flex-row flex-md-column aign-items-center justify-content-center gap-2'>
                                        <div className="row gap-md-2">
                                            <div className="col-2 col-md-12 d-flex flex-column justify-content-center">
                                                <i className='text-danger fa-solid fa-headphones fa-2x'></i>
                                            </div>
                                            <div className="col-10 col-md-12">
                                                <h5 className='text-light'>Best Support</h5>
                                                <p className='text-secondary'>
                                                    We offer top-notch support services.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* service section */}

            <div id='quick-contact'>
                <ContactSection />
            </div>

            {/* Popup form */}
            {showQuoteForm && <QuoteForm onClose={toggleQuoteForm} query={{ service: service, servicePack: selectedServicePack }} />}
        </>
    );
};

const DisplayService = ({ loadService, toggleQuoteForm }) => {
    return (
        <>
            {Object.keys(loadService).length > 0 && (
                <div className='display-service' id='overview'>
                    <div className="display-service-details">
                        <p className="fw-medium">{loadService.ServiceDetails}</p>
                    </div>
                    {/* Create a table to display service points */}
                    <ul className='summary-list service-page-summary'>
                        {loadService.ServicePackages.service_points.map((listItem, index) => (
                            <li key={index} className='summary-list-item'>
                                <span className='fw-semibold text-primary'>{listItem.name}</span>: <span>{listItem.details}</span>
                            </li>
                        ))}
                    </ul>
                    {/* pricinng */}
                    <div className='shadow-lg p-4 rounded-3 d-flex gap-5 justify-content-start align-items-center'>
                        <div className=''>
                            <h5 className='text-danger fs-6'>Starting From</h5>
                            <h5 className='text-primary fs-1'>Rs.<span className='text-dark'>{loadService.ServicePackages.service_pricing}</span></h5>
                        </div>
                        <div className=''>
                            <button className='btn btn-primary' onClick={() => toggleQuoteForm(loadService.ServicePackages.packageName)}>Get Quote</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const QuoteForm = ({ onClose, query }) => {

    const [QuoteFormData, setQuoteFormData] = useState({
        name: "",
        phone: "",
        email: "",
        business: "",
        date: "",
        query: query.servicePack,
        message: ""
    });

    const [FormSubmission, setFormSubmission] = useState({
        status: false,
        message: "",
        state: ""
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phone') {
            const phoneRegex = /^[0-9\b]+$/;
            if (value === '' || (phoneRegex.test(value) && value.length <= 10)) {
                setQuoteFormData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        } else {
            setQuoteFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phone);
    };

    const handleQuoteFormSubmit = async (e) => {
        e.preventDefault();

        if (!validatePhoneNumber(QuoteFormData.phone)) {
            setFormSubmission({
                message: "Invalid phone number! Plzz enter a 10-digit number.",
                status: true,
                state: false
            });
            return;
        }

        try {
            const FormStatus = await axios({
                method: "POST",
                url: "https://websyncbackend.onrender.com/user/quoteform",
                data: QuoteFormData
            });

            const message = FormStatus.data.message.toString();

            setFormSubmission({
                message: message,
                status: true,
                state: true
            });

            setQuoteFormData({
                name: "",
                phone: "",
                email: "",
                business: "",
                date: "",
                query: query.servicePack,
                message: ""
            });

        } catch (err) {
            console.error(err);
            setFormSubmission({
                message: "Sorry! Unable to take a request at this time.",
                status: true,
                state: false
            });
        }
    };

    return (
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: 'block', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: '9999999' }}>
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Book an <span className='text-primary'>Appointment!</span></h5>
                        <button type="button" className="close btn btn-lg" data-dismiss="modal" aria-label="Close" onClick={onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleQuoteFormSubmit}>
                        <div className="modal-body">
                            <div className='row gap-2'>
                                <div className="col">
                                    <div className="form-floating">
                                        <input
                                            name='name'
                                            value={QuoteFormData.name}
                                            type="text"
                                            className='form-control'
                                            id="nameInput"
                                            placeholder=' '
                                            required
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="nameInput">Enter Name</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating">
                                        <input
                                            name='phone'
                                            value={QuoteFormData.phone}
                                            type="tel"
                                            className='form-control'
                                            id="phoneInput"
                                            placeholder=' '
                                            required
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="phoneInput">Contact Number</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input
                                            name='email'
                                            value={QuoteFormData.email}
                                            type="email"
                                            className='form-control'
                                            id="emailInput"
                                            placeholder=' '
                                            required
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="emailInput">Email</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <input
                                            name='business'
                                            value={QuoteFormData.business}
                                            type="text"
                                            className='form-control'
                                            id="businessInput"
                                            placeholder=' '
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="businessInput">Business <span className='text-danger'>(optional)</span></label>
                                    </div>
                                </div>

                                <div className="col-4">
                                    <div className="form-floating">
                                        <input
                                            name='date'
                                            value={QuoteFormData.date}
                                            type="date"
                                            min={new Date().toISOString().split('T')[0]}
                                            className='form-control'
                                            id="dateInput"
                                            placeholder=' '
                                            required
                                            onChange={handleInputChange}
                                        />
                                        <label htmlFor="dateInput">Appointment Date</label>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-floating">
                                        <input
                                            name='query'
                                            type="text"
                                            className='form-control'
                                            id="queryInput"
                                            placeholder=' '
                                            value={QuoteFormData.query}
                                            disabled
                                        />
                                        <label htmlFor="queryInput">Query For!</label>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="form-floating">
                                        <textarea
                                            name='message'
                                            value={QuoteFormData.message}
                                            className='form-control'
                                            id="messageInput"
                                            placeholder=' '
                                            onChange={handleInputChange}
                                            required
                                        ></textarea>
                                        <label htmlFor="messageInput">Message</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={onClose}>Close</button>
                            <button type="submit" className="btn btn-primary">Submit <i className='fa-solid fa-send'></i> </button>
                        </div>
                        {FormSubmission.status && (
                            <div className='alert mt-3 text-center'>
                                <span className={`alert ${FormSubmission.state ? "alert-primary" : "alert-danger"}`}>
                                    {FormSubmission.message}
                                </span>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ServiceContainer;