import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';

// import styling pages 

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"

import "./index.css"

// aos

import "aos/dist/aos.css"
import "aos/dist/aos.js"

import Aos from "aos"

// importing pages

import Home from "./components/pages/Home.jsx"
import Contact from "./components/pages/Contact.jsx"
import Services from "./components/pages/Services.jsx"
import Testimonial from "./components/pages/Testimonial.jsx"
import About from './components/pages/About.jsx'
import ServicesPage from "./components/pages/ServicePage.jsx"
import Blog from "./components/pages/Blog.jsx"
import CoursePage from "./components/pages/CoursePage.jsx"

// import admin dashboard pages
import DashminLogin from './components/dashboard/DashminLogin.jsx';
import Dashboard from './components/dashboard/Dashboard.jsx';
import Blogs from './components/pages/Blogs.jsx';

// aos code 

const App = () => {


  const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);

    return null;
  };

  useEffect(() => {
    Aos.init({
      offset: 200,
      duration: 300,
      easing: 'ease',
    });
  }, [])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<Contact />} />
        {/* made the show all service titles containers */}

        {/* display all blogs */}
        <Route path='/blogs' element={<Blogs />} />
        {/* display each blogs */}
        <Route path='/blogs/:blogTitle' element={<Blog />} />

        <Route path='/:servicepage/' element={<ServicesPage />} />
        {/* this is a api route that can make diffrent pages on diffrent services */}
        <Route path='/:servicescat/:service' element={<Services />} />
        <Route path='/testimonial' element={<Testimonial />} />
        <Route path='/about' element={<About />} />

        {/* Learn with websync course MERN stack */}

        <Route path='learn-to-code' element={<CoursePage />} />

        {/* admin dashbaord login system */}

        <Route path='/dashmin' element={<DashminLogin />} />
        <Route path='/dashboard' element={<Dashboard />} />

      </Routes>
    </BrowserRouter>
  )
}

export default App