import React, { useState } from 'react';
import axios from 'axios';

const ContactSection = () => {
    let [formSubmission, setFormSubmission] = useState({
        status: false,
        message: "",
        state: ""
    });

    const [formData, setFormData] = useState({
        name: '',
        phoneNumber: '',
        email: '',
        enquiryType: '',
        message: '',
        promotions: false
    });

    const validatePhoneNumber = (phone) => {
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(phone);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'phoneNumber') {
            const phoneRegex = /^[0-9\b]+$/;
            if (value === '' || (phoneRegex.test(value) && value.length <= 10)) {
                setFormData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validatePhoneNumber(formData.phoneNumber)) {
            setFormSubmission({
                message: "Invalid phone number! please enter a 10-digit number.",
                status: true,
                state: false
            });
            return;
        }

        let FormStatus;

        try {
            FormStatus = await axios({
                method: "POST",
                url: "https://websyncbackend.onrender.com/user/contactmain",
                data: formData
            });

            const message = FormStatus.data.message.toString();

            setFormSubmission({
                message: message,
                status: true,
                state: true
            });

        } catch (err) {
            console.error(err);

            setFormSubmission({
                message: "Sorry ! unable to take a request at this time.",
                status: true,
                state: false
            });
        }
    };

    return (
        <>
            <div className='container-fluid'>
                <div className="container">
                    <div className="row my-5">
                        <div className="col-12 col-md">
                            <h2>Get <span className='text-primary'> In Touch !</span></h2>
                            <div className='contact-form h-100 w-100'>
                                {
                                    formSubmission.status ?
                                        <div className='h-100 w-100 d-flex flex-column justify-content-center align-items-center'>
                                            <span className={`alert fw-medium ${formSubmission.state ? "alert-primary" : "alert-danger"}`}>
                                                {formSubmission.message}
                                            </span>
                                            <button className='btn btn-primary' onClick={() => {
                                                setFormSubmission({
                                                    status: false,
                                                    message: "",
                                                    state: ""
                                                })
                                            }}>Retry</button>
                                        </div> :
                                        <form onSubmit={handleSubmit}>
                                            <div className='row'>
                                                <div className="col-12 col-md">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text rounded-0 rounded-start bg-primary fw-bold text-light" id="basic-addon1">Name</span>
                                                        </div>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Full Name'
                                                            type="text"
                                                            name="name"
                                                            value={formData.name}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text rounded-0 rounded-start bg-primary fw-bold text-light" id="basic-addon1">Contact</span>
                                                        </div>
                                                        <input
                                                            className='form-control'
                                                            placeholder='Phone Number'
                                                            type="text"
                                                            name="phoneNumber"
                                                            value={formData.phoneNumber}
                                                            onChange={handleChange}
                                                            required
                                                            maxLength="10"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-12 col-md-12">
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text rounded-0 rounded-start bg-primary fw-bold text-light" id="basic-addon1">@</span>
                                                        </div>
                                                        <input
                                                            type="email"
                                                            className="form-control"
                                                            placeholder="Email Address"
                                                            name="email"
                                                            value={formData.email}
                                                            onChange={handleChange}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-12 col-md'>
                                                    <div className="input-group mb-3">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text rounded-0 rounded-start bg-primary fw-bold text-light" id="basic-addon1">Enquiries</span>
                                                        </div>
                                                        <select
                                                            className='form-select'
                                                            name="enquiryType"
                                                            value={formData.enquiryType}
                                                            onChange={handleChange}
                                                            required
                                                        >
                                                            <option value="">Please Select The Enquiry Type !</option>
                                                            <option value="Website For Business">Website For Business</option>
                                                            <option value="Digital Marketing Plans">Digital Marketing Plans</option>
                                                            <option value="Custom Business Software">Custom Business Software</option>
                                                            <option value="Learn To Code With Professional Developers">Learn To Code With Professional Developers</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="mb-3">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text rounded-0 rounded-top bg-primary fw-bold text-light" id="basic-addon1">Message For Us</span>
                                                    </div>
                                                    <textarea
                                                        className='form-control'
                                                        name="message"
                                                        cols="30"
                                                        rows="7"
                                                        placeholder='Enter Message'
                                                        value={formData.message}
                                                        onChange={handleChange}
                                                        required
                                                    ></textarea>
                                                </div>
                                            </div>

                                            <div className='container-fluid'>
                                                <div className="container text-center my-3 d-flex flex-wrap justify-content-between">
                                                    <div className='d-flex gap-3 align-items-center'>
                                                        <span className='fw-bold'>SignUp for Promotional Content</span>
                                                        <input type="checkbox" className='form-check' name='promotions' onClick={() => { formData.promotions = !formData.promotions }} />
                                                    </div>
                                                    <div className=' d-flex flex-row gap-3 justify-content-center'>
                                                        <button type='submit' className='btn btn-primary'>Submit <i className='fa-solid fa-send'></i> </button>
                                                        <button type='reset' className='btn btn-danger'>Reset <i className="fa-solid fa-rotate"></i> </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                }

                            </div>
                        </div>
                        <div className="col-12 col-md">
                            <div className='p-md-2 w-100 h-100'>
                                <iframe className='w-100 h-100 footer-map' title='websync solution google maps' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3722.1633496125573!2d79.11455889999999!3d21.1060528!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4c7a22136ebdd%3A0xcfd3c2f3ba3e0879!2sWebSync%20Solution!5e0!3m2!1sen!2sin!4v1706265095925!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ContactSection;
