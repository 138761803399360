import React from 'react'

const Course = () => {
  return (
    <>
      <div style={{height:"550px"}} className='row course-heros-section'>
        <div className='course-text col'>
            <h1>Become a Full Stack Developer</h1>
        </div>
        <div className='col course-hero'>
            <div style={{width:"250px",height:"250px"}} className='bg-black rounded-circle'></div>
        </div>
      </div>
    </>
  )
}

export default Course
