import React from 'react'

// importing navbar
import Navbar from './sections/Navbar.jsx'
import Footer from './sections/Footer.jsx'
import Projects from './sections/Projects.jsx'
import PostCase from './sections/PostCase.jsx'
import ContactSection from './sections/ContactSection.jsx'
import Sidebar from './sections/Sidebar.jsx'
import SocialMediaSidebar from './sections/SocialMediaSidebar.jsx'


const Testimonial = () => {
  return (
    <>
      <Navbar />

      <Sidebar />

      <SocialMediaSidebar />

      <Projects />

      <PostCase />

      <ContactSection />

      <Footer />
    </>
  )
}

export default Testimonial