import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const DisplayBlog = () => {
    let { blogTitle } = useParams();

    const [blog, setBlog] = useState({});

    useEffect(() => {
        const fetchBlog = async () => {
            try {
                let response = await axios.get(`https://websyncbackend.onrender.com/blog-posts?blogTitle=${blogTitle}`);
                setBlog(response.data);
            } catch (err) {
                console.log("Cannot load this blog now!", err);
            }
        };

        fetchBlog();
    }, [blogTitle]);

    const blogHero = {
        background: `linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)), url("https://websyncbackend.onrender.com${blog.thumbnailUrl}") center center / cover no-repeat`,
        height: "450px"
    };

    return (
        <>
            <div className="container-fluid p-5" style={blogHero}>
                <div className="container w-100 h-100 d-flex justify-content-center align-items-center">
                    <h1 className='text-white'>{blog.title}</h1>
                </div>
            </div>
            {/* Put the HTML that is stored in database */}
            <div className="container-fluid py-5">
                <div className="container">
                    <div dangerouslySetInnerHTML={{ __html: blog.content }} />
                </div>
            </div>
        </>
    );
}

export default DisplayBlog;
