import React from 'react'

// importing navbar
import Navbar from './sections/Navbar.jsx'
import Footer from './sections/Footer.jsx'
import Sidebar from './sections/Sidebar.jsx'
import SocialMediaSidebar from './sections/SocialMediaSidebar.jsx'
import AboutSection from './sections/AboutSection.jsx'
import SolutionFor from './sections/SolutionFor.jsx'
import TechStack from './sections/TechStack.jsx'
import ContactSection from './sections/ContactSection.jsx'

const About = () => {
    return (
        <>
            <Navbar />

            <Sidebar />
            {/* social media sidebar */}
            <SocialMediaSidebar />
            
            {/* about heros section starts */}

            <div className='container-fluid'>
                <div className="container">
                    
                </div>
            </div>

            {/* about heros section ends */}

            <AboutSection />
            {/* solution for */}

            <SolutionFor />

            <TechStack />

            <ContactSection />

            <Footer />
        </>
    )
}

export default About