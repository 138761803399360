import React from 'react'

// importing navbar
import Navbar from './sections/Navbar.jsx'
import Footer from './sections/Footer.jsx'
import Sidebar from './sections/Sidebar.jsx'
import SocialMediaSidebar from './sections/SocialMediaSidebar.jsx'
import DisplayBlog from './sections/DisplayBlog.jsx'


const Blog = () => {
  return (
    <>
      <Navbar />

      <Sidebar />
      {/* social media sidebar */}
      <SocialMediaSidebar />
      {/* heros section */}

      <DisplayBlog />

      <Footer />
    </>
  )
}

export default Blog